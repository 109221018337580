import React from 'react';
import { MultiStep } from 'components/MultiStep';
import { Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { BankIDProgressInfo } from './components/BankIDProgressInfo';
import { BankIDPersonalNumber } from './components/BankIDPersonalNumber';

export const BankID = ({
  name,
  onInit,
  initLoading,
  initSuccess,
  error,
  userVisibleData,
}) => (
  <>
    <MultiStep>
      <MultiStep.Step>
        <BankIDPersonalNumber
          onProceed={onInit}
          loading={initLoading}
          name={name}
          initSuccess={initSuccess}
          userVisibleData={userVisibleData}
        />
      </MultiStep.Step>

      <MultiStep.Step>
        <BankIDProgressInfo />
      </MultiStep.Step>
    </MultiStep>

    <ShouldRender condition={!!error}>
      <Message negative>
        <FormattedMessage id={error} />
      </Message>
    </ShouldRender>
  </>
);
