import React, { createContext, useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import movingOutImgSrc from 'assets/media/moving_out.png';
import { MovingOutInfo } from 'components/brikks/home/MovingOut/components/MovingOutModal/components/MovingOutInfo';

export const MovingOutModalContext = createContext();

export const MovingOutModal = () => {
  const [activeMenu, setActiveMenu] = useState('terminateAgreements');

  return (
    <MovingOutModalContext.Provider value={{ activeMenu, setActiveMenu }}>
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="movingOut" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="movingOut"
          >
            <ModalIntro>
              <ModalIntro.Image
                src={movingOutImgSrc}
                alt="Moving out"
              />

              <ModalIntro.Text intl="movingOutDescription" />
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <MovingOutInfo />
      </Grid>
    </MovingOutModalContext.Provider>
  );
};
