import React, { useState } from 'react';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { Brikk } from 'components/Brikk';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { UpgradesIcon } from 'assets/icons/brikks/UpgradesIcon';
import { UpgradesPreview } from 'components/brikks/home/Upgrades/components/UpgradesPreview';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { UpgradesModal } from 'components/brikks/home/Upgrades/components/UpgradesModal';

export const Upgrades = ({ position, code, status, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          status={status}
          name={name}
        >
          <Brikk.Header
            icon={<UpgradesIcon />}
            heading="upgradesBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content center>
            <UpgradesPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="upgrades"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Upgrades info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="upgrades"
        onClose={() => setIsModalOpen(false)}
      >
        <UpgradesModal />
      </BrikkModal>
    </>
  );
};
