import React, { useContext } from 'react';
import { Document, Page } from 'react-pdf';
import { ErrorBoundary } from 'react-error-boundary';
import { Text } from 'components/typography/Text';
import { DocumentPreviewContext } from 'components/media/DocumentPreview';
import styles from './styles.module.scss';

export const Preview = () => {
  const { url } = useContext(DocumentPreviewContext);

  return (
    <div className={styles.preview}>
      <ErrorBoundary FallbackComponent={<Text intl="genericErrorMessage" />}>
        <Document
          file={url}
          loading={(
            <Text
              center
              dark
              intl="loadingDocument"
            />
          )}
        >
          <Page
            pageNumber={1}
            width={110}
            height={148}
          />
        </Document>
      </ErrorBoundary>
    </div>
  );
};
