import React, { useContext } from 'react';
import { CleaningAndMaintenanceModalContext } from
  'components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/index';
import { Dropdown } from 'components/dropdowns/Dropdown';
import { Text } from 'components/typography/Text';

export const TabMenu = ({ menuName, subMenus }) => {
  const { activeMenu, setActiveMenu } = useContext(CleaningAndMaintenanceModalContext);

  return (
    <Dropdown
      key={menuName}
      open={activeMenu === menuName}
    >
      <Dropdown.Item
        intl={menuName}
        onClick={() => setActiveMenu(menuName)}
      />
      <Dropdown.Content>
        <Text
          intl={subMenus}
          size={14}
        />
      </Dropdown.Content>
    </Dropdown>
  );
};
