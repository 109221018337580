import whiteBg from 'assets/media/bg.jpg';
import darkBg from 'assets/media/dark-bg.jpg';
import treesBg from 'assets/media/trees-bg.jpg';
import darkThemeBg from 'assets/media/dark-theme-bg.jpg';
import wxtNatureBg from 'assets/media/wxt-background.jpg';
import sfgBackground from 'assets/media/sfg-background.jpg';
import sfgGreenBg from 'assets/media/sfg-green-bg.jpg';
import tornetWhiteBg from 'assets/media/torn/tornet-bg.png';
import heimWhiteBg from 'assets/media/heim/heim-bg.png';
import lightGrayBg from 'assets/media/white-bg.jpg';
import blueBg from 'assets/media/blue-bg.jpg';
import lundBg from 'assets/media/lund-bg.jpg';
import svalBg from 'assets/media/sval/background.jpg';
import dreBg from 'assets/media/dre/background.png';
import croBg from 'assets/media/cro/background.png';
import grtBg from 'assets/media/grt/background.png';
import brf1Bg from 'assets/media/brf/background-brf1.png';
import brf2Bg from 'assets/media/brf/background-brf2.png';
import brf3Bg from 'assets/media/brf/background-brf3.png';
import brf4Bg from 'assets/media/brf/background-brf4.png';
import brf5Bg from 'assets/media/brf/background-brf5.png';
import brf6Bg from 'assets/media/brf/background-brf6.png';
import brf7Bg from 'assets/media/brf/background-brf7.png';
import brf8Bg from 'assets/media/brf/background-brf8.png';
import brf9Bg from 'assets/media/brf/background-brf9.png';
import brf10Bg from 'assets/media/brf/background-brf10.png';
import brf11Bg from 'assets/media/brf/background-brf11.png';
import brf12Bg from 'assets/media/brf/background-brf12.png';
import brf13Bg from 'assets/media/brf/background-brf13.png';
import brf14Bg from 'assets/media/brf/background-brf14.png';
import brf15Bg from 'assets/media/brf/background-brf15.png';
import brf16Bg from 'assets/media/brf/background-brf16.png';
import brf17Bg from 'assets/media/brf/background-brf17.png';
import brf18Bg from 'assets/media/brf/background-brf18.png';
import jernhusenBg from 'assets/media/demo/jernhusen-bg.png';
import skurupshemBg from 'assets/media/demo/skurupshem-bg.png';
import { AppTracking } from 'global/appTracking';
import { MultiGrid } from 'services/multi-grid/multiGrid';

export const WHITE_BG = whiteBg;

export const DARK_BG = darkBg;

export const TREES_BG = treesBg;

export const WXT_NATURE_BG = wxtNatureBg;

export const SFG_BG = sfgBackground;

export const SFG_GREEN_BG = sfgGreenBg;

export const DARK_THEME_BG = darkThemeBg;

export const TORNET_BG = tornetWhiteBg;

export const HEIM_BG = heimWhiteBg;

export const LIGHT_GRAY_BG = lightGrayBg;

export const BLUE_BG = blueBg;

export const LUND_BG = lundBg;

export const SVAL_BG = svalBg;

export const DRE_BG = dreBg;

export const CRO_BG = croBg;

export const GRT_BG = grtBg;

export const BRF1_BG = brf1Bg;
export const BRF2_BG = brf2Bg;
export const BRF3_BG = brf3Bg;
export const BRF4_BG = brf4Bg;
export const BRF5_BG = brf5Bg;
export const BRF6_BG = brf6Bg;
export const BRF7_BG = brf7Bg;
export const BRF8_BG = brf8Bg;
export const BRF9_BG = brf9Bg;
export const BRF10_BG = brf10Bg;
export const BRF11_BG = brf11Bg;
export const BRF12_BG = brf12Bg;
export const BRF13_BG = brf13Bg;
export const BRF14_BG = brf14Bg;
export const BRF15_BG = brf15Bg;
export const BRF16_BG = brf16Bg;
export const BRF17_BG = brf17Bg;
export const BRF18_BG = brf18Bg;
export const JERNHUSEN_BG = jernhusenBg;
export const SHURUPSHEM_BG = skurupshemBg;

export function trackUpdateSettingsEvent({ theme, background, brikksOrder, onboardingPassed }) {
  if (theme) {
    AppTracking.settingsEvent({ action: 'Change Theme', label: theme });
  }

  if (background) {
    switch (background) {
      case DARK_BG:
        AppTracking.settingsEvent({ action: 'Change Background', label: 'Dark green' });
        break;
      case WHITE_BG:
        AppTracking.settingsEvent({ action: 'Change Background', label: 'White' });
        break;
      case TREES_BG:
        AppTracking.settingsEvent({ action: 'Change Background', label: 'Green' });
        break;
      case DARK_THEME_BG:
        AppTracking.settingsEvent({ action: 'Change Background', label: 'Dark' });
        break;
      default:
        break;
    }
  }

  if (brikksOrder) {
    AppTracking.settingsEvent({ action: 'Change Brikks Order' });
  }

  if (onboardingPassed) {
    AppTracking.onboardingEvent({ action: 'Onboarding Complete' });
  }
}

export const defaultBrikksOrder = {
  [MultiGrid.Home]: [],
  [MultiGrid.Neighbourhood]: [],
  [MultiGrid.Society]: [],
  [MultiGrid.Sustainability]: [],
  [MultiGrid.Finances]: [],
  [MultiGrid.Care]: [],
  [MultiGrid.Smartness]: [],
  [MultiGrid.Store]: [],
};
