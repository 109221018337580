import React from 'react';
import classNames from 'classnames';
import { CircledArrowIcon } from 'assets/icons/CircledArrowIcon';
import styles from './styles.module.scss';

export const CircledArrow = ({ dark, left, light }) => (
  <div
    className={classNames(
      styles.circledArrow,
      dark && styles.dark,
      light && styles.light,
    )}
  >
    <CircledArrowIcon
      left={left}
      light={light}
    />
  </div>
);
