import React from 'react';
import { tenantConfig } from 'global/tenant-config';
import styles from './styles.module.scss';
import { MultiGridItem } from './components/MultiGridItem';

export const MultiGrid = () => (
  <div className={styles.multiGrid}>
    {tenantConfig.multiGrids.map(multiGrid => (
      <MultiGridItem
        key={multiGrid}
        grid={multiGrid}
      />
    ))}
  </div>
);
