import React from 'react';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const ItemName = ({ residualValue, name }) => (
  <div className={styles.ItemName}>
    <ShouldRender condition={residualValue === 0}>
      <div className={styles.greenDot} />
    </ShouldRender>

    {name}
  </div>
);
