import { daysFormat, formatDate } from 'utils/timeAndDate';

export const mapDataForClient = (agreement) => ({
  agreementNumber: agreement.agreementNumber,
  productType: agreement.product,
  productNumber: agreement.productNumber,
  agreementFrom: agreement.startDate ? formatDate(new Date(agreement.startDate), daysFormat) : '-',
  uppsTid: agreement.uppsTid,
  groundRent: agreement.rent,
  surface: agreement.surface,
  address: agreement.address,
  hasAgreementPdf: agreement.hasAgreementPdf,
  customerFrom: agreement.customerFrom,
  status: agreement.status,
  secondPersonId: agreement.secondPersonId,
  secondPersonData: agreement.secondPersonData,
  movingOutDate: agreement.movingOutDate,
  secondPersonSignedTermination: agreement.secondPersonSignedTermination,
});
