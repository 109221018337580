export const DownloadInvoiceActions = {
  DOWNLOAD_INVOICE: '@invoice/download/',
  DOWNLOAD_INVOICE_SUCCESS: '@invoice/download/success',
  DOWNLOAD_INVOICE_FAIL: '@invoice/download/fail',
};

export function downloadInvoice(invoiceNumber, invoiceType) {
  return {
    type: DownloadInvoiceActions.DOWNLOAD_INVOICE,
    payload: { invoiceNumber, invoiceType },
  };
}

export function downloadInvoiceSuccess(invoiceNumber, invoiceType) {
  return {
    type: DownloadInvoiceActions.DOWNLOAD_INVOICE_SUCCESS,
    payload: { invoiceNumber, invoiceType },
  };
}

export function downloadInvoiceFail(error, invoiceNumber, invoiceType) {
  return {
    type: DownloadInvoiceActions.DOWNLOAD_INVOICE_FAIL,
    payload: { error, invoiceNumber, invoiceType },
  };
}
