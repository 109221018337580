import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import sharingIsCaring from 'assets/media/sharing-is-caring.jpg';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { SharingIsCaringIcon } from 'assets/icons/brikks/SharingIsCaringIcon';
import { LinkBrikkContent } from 'components/brikks/sub-components/LinkBrikkContent';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const SharingIsCaring = ({ position, status, code, name }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };
  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<SharingIsCaringIcon />}
            heading="sharingIsCaring"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <CircularImage
              src={sharingIsCaring}
              alt="Sharing Is Caring"
            />

            <LinkBrikkContent link="hygglo.se" />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="Sharing Is Caring Link"
              to="https://hygglo.se/"
              brikkNameIntl="sharing"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      
      <BrikkInfoModal
        title="Sharing Is Caring info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

    </>
  );
};
