export const filmsData = [
  {
    title: 'serviceorder',
    videUrl: 'https://www.youtube.com/embed/8yTLHgNkEwg',
  },
  {
    title: 'orderMaintenance',
    videUrl: 'https://www.youtube.com/embed/dfFYathj-5I',
  },
];
