import React, { useContext } from 'react';
import { Placeholder } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { BrikkContext } from 'components/Brikk/index';
import styles from './styles.module.scss';

const ErrorComponent = ({ error }) => (
  <div className={styles.error}>
    <FormattedMessage id={error} />
  </div>
);

const LoadingComponent = () => (
  <div className={styles.loading}>
    <Placeholder>
      <Placeholder.Line className={styles.line} />
      <Placeholder.Line className={styles.line} />
      <Placeholder.Line className={styles.line} />
      <Placeholder.Line className={styles.line} />
    </Placeholder>
  </div>
);

export const Content = ({ center, children, className }) => {
  const { isLoading, error } = useContext(BrikkContext);

  let content;
  if (error) {
    content = <ErrorComponent error={error} />;
  } else if (isLoading) {
    content = <LoadingComponent />;
  } else {
    content = children;
  }

  return (
    <div
      className={classNames(
        styles.brikkContent,
        {
          [styles.center]: center,
        },
        className,
      )}
    >
      {content}
    </div>
  );
};
