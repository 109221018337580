import { BUILDING, CLIMATE, HOME, INTERNET_PROVIDER, LIGHTS, PRIVATE, PUBLIC, SERVICE } from 'utils/types';

function mapPropertyType(type) {
  switch (type) {
    case PRIVATE:
      return HOME;
    case PUBLIC:
      return BUILDING;
    default:
      return type;
  }
}

export const mapDataForBackend = (report) => ({
  area: report.area,
  publicSpaceId: report.publicSpaceId,
  realestate: report.realestate,
  building: report.building,
  stairwell: report.stairwell,
  space: report.space,
  unit: report.unit,
  object: report.object,
  email: report.email,
  key: report.key,
  attachedFiles: report.attachedFiles,
  description: `${report.category ? `${report.category} | ` : ''}${report.description} | Kontakt via: ${report.telNumber}`,
  motivation: report.motivation,
  pets: report.pets,
});

export const mapDataForBackendLKF = (report) => ({
  realestate: report.realestate,
  object: report.object,
  building: report.building,
  email: report.email,
  attachedFiles: report.attachedFiles,
  description: `${report.description}\n\n\n${[PRIVATE, PUBLIC].includes(report.propertyType)
    ? `${report.spaceName}${report.unitName ? ` | ${report.unitName}` : ''}`
    : `${report.category
      ? `${report.category}`
      : ''}`}\nKontakt via: ${report.telType === 'other' ? `Annat: ${report.telNumber}` : report.telNumber}`,
  pets: report.pets,
  key: report.key,
  type: mapPropertyType(report.propertyType),
  ...(report.propertyType === SERVICE && report.service !== INTERNET_PROVIDER && { productId: report.service }),
});

export const mapDataForBackendRIB = (report) => ({
  type: [LIGHTS, CLIMATE].includes(report.service) ? report.service : report.propertyType,
  ...(report.propertyType === SERVICE && ![INTERNET_PROVIDER, LIGHTS, CLIMATE].includes(report.service) && { productId: report.service }),
  description: `${report.category
    ? `${report.category} | `
    : ''}${report.description}${report.pets && ` | Husdjur: ${report.pets}`}\n\nKontakt via: ${report.telNumber}`,
  attachedFiles: report.attachedFiles,
});
