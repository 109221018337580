import React from 'react';
import { Text } from '../../../../typography/Text';
import { DiscardIcon } from '../../../../../assets/icons/DiscardIcon';
import styles from './styles.module.scss';

export const UploadedImage = ({ content, name, size, onRemove }) => (
  <div className={styles.uploadedImage}>
    <img
      className={styles.image}
      src={content}
      alt={name}
    />

    <div className={styles.description}>
      <Text
        bold
        lightGray
        elipsis
      >
        {name}
      </Text>

      <Text
        small
        lightGray
      >
        {Math.round(size / 1000)}KB
      </Text>
    </div>

    <div className={styles.remove}>
      <DiscardIcon onClick={() => onRemove(name)} />
    </div>
  </div>
);
