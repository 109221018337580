import React from 'react';
import { tenantConfig } from 'global/tenant-config';
import { MenuItem } from './components/MenuItem';

export const MobileMenu = ({ onClick }) => (
  <div onClick={onClick}>
    {tenantConfig.multiGrids.map(multiGrid => (
      <MenuItem
        key={multiGrid}
        multiGrid={multiGrid}
      />
    ))}
  </div>
);
