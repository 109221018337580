export const InitPassResetActions = {
  INIT_PASS_RESET: '@initPassReset/',
  INIT_PASS_RESET_SUCCESS: '@initPassReset/success',
  INIT_PASS_RESET_FAIL: '@initPassReset/fail',

  INIT_PASS_RESET_RESET: '@initPassReset/reset',
};

export function initPassReset(email) {
  return {
    type: InitPassResetActions.INIT_PASS_RESET,
    payload: email,
  };
}

export function initPassResetSuccess() {
  return {
    type: InitPassResetActions.INIT_PASS_RESET_SUCCESS,
  };
}

export function initPassResetFail(error) {
  return {
    type: InitPassResetActions.INIT_PASS_RESET_FAIL,
    payload: error,
  };
}

export function initPassResetReset() {
  return {
    type: InitPassResetActions.INIT_PASS_RESET_RESET,
  };
}
