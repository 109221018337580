import React from 'react';
import { Icon } from 'semantic-ui-react';
import { DropdownButton } from 'components/form-elements/buttons/DropdownButton';
import { ShouldRender } from 'components/ShouldRender';
import styles from 'components/dropdowns/SingleDropdown/components/Item/styles.module.scss';

export const Item = ({ children, item, itemKey, intl, onClick, selectedItem }) => {

  const isActive = selectedItem === item;

  const handleOnClick = () => {
    if (onClick) onClick();
  };

  return (
    <DropdownButton
      intl={intl}
      onClick={() => handleOnClick()}
      active={isActive}
    >
      {children}

      <ShouldRender condition={!!itemKey}>
        <Icon
          className={styles.icon}
          name={isActive ? 'angle down' : 'angle right'}
        />
      </ShouldRender>
    </DropdownButton>
  );
};
