import React from 'react';
import Loader from '../Loader';
import styles from './styles.module.scss';

export const PageLoader = () => (
  <div className={styles.container}>
    <Loader />
  </div>
);

export default Loader;
