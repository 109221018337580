import React from 'react';
import { Text } from 'components/typography/Text';
import { Radio } from 'components/form-elements/radios/Radio';
import { DARK_THEME, FLAT_THEME, useTheme, TRANSPARENT_THEME } from 'global/themes';
import { TREES_BG, WHITE_BG, DARK_THEME_BG } from 'services/user/settings/utils';
import { TENANT_ID } from 'global/environment';
import { AppContext } from 'AppContext';
import { GRT, RIB } from 'global/tenant-config/tenants';
import styles from './styles.module.scss';

export function ThemeSettings() {
  const { currentTheme: theme, setTheme } = useTheme();
  const { userSettings: { updateUserSettings }, selectedTheme } = React.useContext(AppContext);

  const disabled = (selectedTheme !== TENANT_ID || [RIB, GRT].includes(TENANT_ID));

  React.useEffect(() => {
    if (disabled && theme !== FLAT_THEME) {
      setTheme(FLAT_THEME);
    }
    // eslint-disable-next-line 
  }, [disabled]);

  return (
    <div className={styles.themeSettings}>
      <div className={styles.headingWrapper}>
        <Text
          element="h3"
          thin
          medium
          uppercase
          white
          intl="appearance"
          className={styles.heading}
        />
      </div>

      <div className={styles.settings}>
        <div className={styles.checkbox}>
          <Text
            white
            intl="solid"
          />

          <Radio
            disabled={disabled}
            toggle
            checked={
              theme !== DARK_THEME
              && theme !== TRANSPARENT_THEME
            }
            onChange={() => {
              if (!disabled) {
                setTheme(FLAT_THEME);
                updateUserSettings({ background: WHITE_BG });
              }
            }}
          />
        </div>

        <div className={styles.checkbox}>
          <Text
            white
            intl="transparent"
          />

          <Radio
            disabled={disabled}
            toggle
            checked={theme === TRANSPARENT_THEME}
            onChange={() => {
              if (!disabled) {
                setTheme(TRANSPARENT_THEME);
                updateUserSettings({ background: TREES_BG });
              }
            }}
          />
        </div>

        <div className={styles.checkbox}>
          <Text
            white
            intl="dark"
          />

          <Radio
            disabled={disabled}
            toggle
            checked={theme === DARK_THEME}
            onChange={() => {
              if (!disabled) {
                setTheme(DARK_THEME);
                updateUserSettings({ background: DARK_THEME_BG });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
