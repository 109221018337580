import React from 'react';
import { store } from 'store';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { TranslationsProvider } from 'components/intl/IntlProvider';
import { PageTracking } from 'components/tracking';
import { ScrollToTop } from 'components/ScrollToTop';
import { tenantConfig } from 'global/tenant-config';
import { ServiceProvider } from 'services';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Helmet } from 'react-helmet';
import 'semantic-ui-css/semantic.min.css';
import { ModalProvider } from 'store/modals';
import { Dashboard } from '../../components/Dashboard';
import { WelcomeScreenGuard } from '../../components/guards/WelcomeScreenGuard';
import { OnboardingGuard } from '../../components/guards/OnboardingGuard';
import { ContextProvider } from '../../AppContext';

export const GlobalProvider = ({ children }) => (
  <Router>
    <ScrollToTop />
    <Helmet>
      <link
        rel="icon"
        type="image/png"
        href={tenantConfig.assets.favicon}
        sizes="16x16"
      />
    </Helmet>

    <ServiceProvider>
      <Provider store={store}>
        <ContextProvider>
          <TranslationsProvider>
            <Dashboard>
              <WelcomeScreenGuard>
                <OnboardingGuard>
                  <ModalProvider>
                    {children}
                  </ModalProvider>
                </OnboardingGuard>
              </WelcomeScreenGuard>
            </Dashboard>
          </TranslationsProvider>
        </ContextProvider>
      </Provider>

      <ReactQueryDevtools />
    </ServiceProvider>
    <PageTracking />
  </Router>
);
