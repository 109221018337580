export const parseAddress = (address, apartmentNo) => {
  let parsedAddress;

  try {
    [parsedAddress] = address.replace(/\s*,\s*/g, ', ').split(' Lgh');
    if (apartmentNo) {
      parsedAddress += `, lgt ${apartmentNo}`;
    }
  } catch (e) {
    parsedAddress = address;
  }

  return parsedAddress;
};

export const shortenAddress = (address) => {
  const splitAddress = address.split(',');
  return splitAddress ? splitAddress[0] : address;
};

export const parseNumber = (number) => (number.toString().length === 1 ? `0${number}` : number);

export const parseDateDistanceToSwedish = (dateDistance) => {
  const [time, timeUnit] = dateDistance.split(' ');

  switch (timeUnit) {
    case 'second':
      return `${time} sekund sedan`;
    case 'seconds':
      return `${time} sekunder sedan`;
    case 'minute':
      return `${time} minut sedan`;
    case 'minutes':
      return `${time} minuter sedan`;
    case 'hour':
      return `${time} timme sedan`;
    case 'hours':
      return `${time} timmar sedan`;
    case 'day':
      return `${time} dag sedan`;
    case 'days':
      return `${time} dagar sedan`;
    case 'month':
      return `${time} månad sedan`;
    case 'months':
      return `${time} månader sedan`;
    case 'year':
      return `${time} år sedan`;
    case 'years':
      return `${time} år sedan`;
    default:
      return dateDistance;
  }
};
