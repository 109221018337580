import { takeLatest, call, put } from 'redux-saga/effects';
import {
  SubmitCreateOrderActions,
  submitCreateOrderFail,
  submitCreateOrderSuccess,
} from './actions';
import { createOrder } from './api';

function* submitCreateOrderEffect({ payload }) {
  try {
    yield call(createOrder, payload);
    yield put(submitCreateOrderSuccess());
  } catch (e) {
    yield put(submitCreateOrderFail(e.message));
  }
}

export const submitCreateOrderEffects = [
  takeLatest(SubmitCreateOrderActions.SUBMIT_CREATE_ORDER, submitCreateOrderEffect),
];
