/* eslint-disable max-len */
export const maintenanceProductsData = [
  {
    typeName: 'Kök',
    unitName: 'Köksluckor/Sprutmålning',
    measure: 'Målas',
    data: [
      {
        name: 'S 0502-Y',
        code: 'S 0502-Y',
        type: 'kitchen-door-spray-painting',
        brand: 'NCS',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 0502-Y.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 0502-Y-80x80.jpg',
        details: {
          'vendor-name': 'NCS',
          'img-names': 'S 0502-Y.jpg;S 0502-Y-80x80.jpg',
        },
      },
      {
        name: 'S 0510-Y10R',
        code: 'S 0510-Y10R',
        type: 'kitchen-door-spray-painting',
        brand: 'NCS',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 0510-Y10R.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 0510-Y10R-80x80.jpg',
        details: {
          'vendor-name': 'NCS',
          'img-names': 'S 0510-Y10R.jpg;S 0510-Y10R-80x80.jpg',
        },
      },
      {
        name: 'S 1500-N',
        code: 'S 1500-N',
        type: 'kitchen-door-spray-painting',
        brand: 'NCS',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 1500-N.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 1500-N-80x80.jpg',
        details: {
          'vendor-name': 'NCS',
          'img-names': 'S 1500-N.jpg;S 1500-N-80x80.jpg',
        },
      },
      {
        name: 'S 2005-Y50R',
        code: 'S 2005-Y50R',
        type: 'kitchen-door-spray-painting',
        brand: 'NCS',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 2005-Y50R.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 2005-Y50R-80x80.jpg',
        details: {
          'vendor-name': 'NCS',
          'img-names': 'S 2005-Y50R.jpg;S 2005-Y50R-80x80.jpg',
        },
      },
      {
        name: 'S 2010-G90Y',
        code: 'S 2010-G90Y',
        type: 'kitchen-door-spray-painting',
        brand: 'NCS',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 2010-G90Y.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-spray-painting/S 2010-G90Y-80x80.jpg',
        details: {
          'vendor-name': 'NCS',
          'img-names': 'S 2010-G90Y.jpg;S 2010-G90Y-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Köksluckor/Slät med rundad kant',
    measure: 'Målas',
    data: [
      {
        name: 'SARA Antikvit',
        code: 'S0502-Y',
        type: 'kitchen-door-smooth-with-rounded-edge',
        brand: 'SARA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-antikvit-S0502-Y.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-antikvit-S0502-Y-80x80.jpg',
        details: {
          'vendor-name': 'SARA',
          'img-names': 'sara-antikvit-S0502-Y.jpg;sara-antikvit-S0502-Y-80x80.jpg',
        },
      },
      {
        name: 'SARA Pärlgrå',
        code: 'S2500-N',
        type: 'kitchen-door-smooth-with-rounded-edge',
        brand: 'SARA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-parlgra-S2500-N.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-parlgra-S2500-N-80x80.jpg',
        details: {
          'vendor-name': 'SARA',
          'img-names': 'sara-parlgra-S2500-N.jpg;sara-parlgra-S2500-N-80x80.jpg',
        },
      },
      {
        name: 'SARA Åskgrå',
        code: 'S4005-R80B',
        type: 'kitchen-door-smooth-with-rounded-edge',
        brand: 'SARA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-askgra-S4005-R80B.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-askgra-S4005-R80B-80x80.jpg',
        details: {
          'vendor-name': 'SARA',
          'img-names': 'sara-askgra-S4005-R80B.jpg;sara-askgra-S4005-R80B-80x80.jpg',
        },
      },
      {
        name: 'SARA Barista',
        code: 'S2005-Y50R',
        type: 'kitchen-door-smooth-with-rounded-edge',
        brand: 'SARA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-barista-beige-S2005-Y50R.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-barista-beige-S2005-Y50R-80x80.jpg',
        details: {
          'vendor-name': 'SARA',
          'img-names': 'sara-barista-beige-S2005-Y50R.jpg;sara-barista-beige-S2005-Y50R-80x80.jpg',
        },
      },
      {
        name: 'SARA Sandbeige',
        code: 'S1502-Y',
        type: 'kitchen-door-smooth-with-rounded-edge',
        brand: 'SARA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-sandbeige-S1502-Y.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-smooth-with-rounded-edge/sara-sandbeige-S1502-Y-80x80.jpg',
        details: {
          'vendor-name': 'SARA',
          'img-names': 'sara-sandbeige-S1502-Y.jpg;sara-sandbeige-S1502-Y-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Köksluckor/Skät med fasettslipad kant',
    measure: 'Målas',
    data: [
      {
        name: 'MINNA Antikvit',
        code: 'S0502-Y',
        type: 'kitchen-door-trimmed-with-faceted-edge',
        brand: 'MINNA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-antikvit.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-antikvit-80x80.jpg',
        details: {
          'vendor-name': 'MINNA',
          'img-names': 'minna-antikvit.jpg;minna-antikvit-80x80.jpg',
        },
      },
      {
        name: 'MINNA Barista beige',
        code: 'S2005-Y50R',
        type: 'kitchen-door-trimmed-with-faceted-edge',
        brand: 'MINNA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-barista-beige-S2005-Y50R.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-barista-beige-S2005-Y50R-80x80.jpg',
        details: {
          'vendor-name': 'MINNA',
          'img-names': 'minna-barista-beige-S2005-Y50R.jpg;minna-barista-beige-S2005-Y50R-80x80.jpg',
        },
      },
      {
        name: 'MINNA Åskgrå',
        code: 'S4005-R80B',
        type: 'kitchen-door-trimmed-with-faceted-edge',
        brand: 'MINNA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-askgra-S4005-R80B.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-askgra-S4005-R80B-80x80.jpg',
        details: {
          'vendor-name': 'MINNA',
          'img-names': 'minna-askgra-S4005-R80B.jpg;minna-askgra-S4005-R80B-80x80.jpg',
        },
      },
      {
        name: 'MINNA Pärlgrå',
        code: 'S2500-N',
        type: 'kitchen-door-trimmed-with-faceted-edge',
        brand: 'MINNA',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-parlgra-S2500-N.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-parlgra-S2500-N-80x80.jpg',
        details: {
          'vendor-name': 'MINNA',
          'img-names': 'minna-parlgra-S2500-N.jpg;minna-parlgra-S2500-N-80x80.jpg',
        },
      },
      {
        name: 'MINNA Sandbeige',
        code: 'S1502-Y',
        type: 'kitchen-door-trimmed-with-faceted-edge',
        brand: 'MINNA',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-sandbeige-S1502-Y.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-trimmed-with-faceted-edge/minna-sandbeige-S1502-Y-80x80.jpg',
        details: {
          'vendor-name': 'MINNA',
          'img-names': 'minna-sandbeige-S1502-Y.jpg;minna-sandbeige-S1502-Y-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Köksluckor/Spårfräst med profilerad kant',
    measure: 'Målas',
    data: [
      {
        name: 'LINA Antikvit',
        code: 'S0502-Y',
        type: 'kitchen-door-grooved-with-profiled-edge',
        brand: 'Lina',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-antikvit-S0502-Y.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-antikvit-S0502-Y-80x80.jpg',
        details: {
          'vendor-name': 'Lina',
          'img-names': 'lina-antikvit-S0502-Y.jpg;lina-antikvit-S0502-Y-80x80.jpg',
        },
      },
      {
        name: 'LINA Barista',
        code: 'S2005-Y50R',
        type: 'kitchen-door-grooved-with-profiled-edge',
        brand: 'Lina',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-barista-beige-S2005-Y50R.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-barista-beige-S2005-Y50R-80x80.jpg',
        details: {
          'vendor-name': 'Lina',
          'img-names': 'lina-barista-beige-S2005-Y50R.jpg;lina-barista-beige-S2005-Y50R-80x80.jpg',
        },
      },
      {
        name: 'LINA Pärlgrå',
        code: 'S2500-N',
        type: 'kitchen-door-grooved-with-profiled-edge',
        brand: 'Lina',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-pärlgrå-S2500-N.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-pärlgrå-S2500-N-80x80.jpg',
        details: {
          'vendor-name': 'Lina',
          'img-names': 'lina-pärlgrå-S2500-N.jpg;lina-pärlgrå-S2500-N-80x80.jpg',
        },
      },
      {
        name: 'LINA Sandbeige',
        code: 'S1502-Y',
        type: 'kitchen-door-grooved-with-profiled-edge',
        brand: 'Lina',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-sandbeige-S1502-Y.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-sandbeige-S1502-Y-80x80.jpg',
        details: {
          'vendor-name': 'Lina',
          'img-names': 'lina-sandbeige-S1502-Y.jpg;lina-sandbeige-S1502-Y-80x80.jpg',
        },
      },
      {
        name: 'LINA Åskgrå',
        code: 'S4005-R80B',
        type: 'kitchen-door-grooved-with-profiled-edge',
        brand: 'Lina',
        price: 2900,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-askgra-S4005-R80B.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-door-grooved-with-profiled-edge/lina-askgra-S4005-R80B-80x80.jpg',
        details: {
          'vendor-name': 'Lina',
          'img-names': 'lina-askgra-S4005-R80B.jpg;lina-askgra-S4005-R80B-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Kökshandtag',
    measure: 'Utbyte',
    data: [
      {
        name: 'Krom',
        code: '0143-krom',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/0143-krom.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/0143-krom-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': '0143-krom.jpg;0143-krom-80x80.jpg',
        },
      },
      {
        name: '735-rostfri-look',
        code: '735',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/735-rostfri-look.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/735-rostfri-look-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': '735-rostfri-look.jpg;735-rostfri-look-80x80.jpg',
        },
      },
      {
        name: '7860-alu-look',
        code: '7860-alu-look',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/7860-alu-look.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/7860-alu-look-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': '7860-alu-look.jpg;7860-alu-look-80x80.jpg',
        },
      },
      {
        name: '7860-svart',
        code: '7860-svart',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/7860-svart.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/7860-svart-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': '7860-svart.jpg;7860-svart-80x80.jpg',
        },
      },
      {
        name: 'A5 Ek',
        code: 'A5',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/a5-ek.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/a5-ek-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'a5-ek.jpg;a5-ek-80x80.jpg',
        },
      },
      {
        name: 'arild',
        code: 'arild',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/arild.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/arild-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'arild.jpg;arild-80x80.jpg',
        },
      },
      {
        name: 'malen-rostfri-look',
        code: 'malen-rostfri-look',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/malen-rostfri-look.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/malen-rostfri-look-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'malen-rostfri-look.jpg;malen-rostfri-look-80x80.jpg',
        },
      },
      {
        name: 'terni-rostfri-look',
        code: 'terni-rostfri-look',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/terni-rostfri-look.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/terni-rostfri-look-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'terni-rostfri-look.jpg;terni-rostfri-look-80x80.jpg',
        },
      },
      {
        name: 'vsc-rostfri-look',
        code: 'vsc-rostfri-look',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/vsc-rostfri-look.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/vsc-rostfri-look-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'vsc-rostfri-look.jpg;vsc-rostfri-look-80x80.jpg',
        },
      },
      {
        name: 'vsc',
        code: 'vsc',
        type: 'kitchen-handle',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/vsc.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-handle/vsc-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'vsc.jpg;vsc-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Bänkskiva',
    measure: 'Utbyte',
    data: [
      {
        name: 'Ask Trä 25',
        code: 'ask-tra-25',
        type: 'kitchen-worktop',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/ask-tra-25.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/ask-tra-25-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'ask-tra-25.jpg;ask-tra-25-80x80.jpg',
        },
      },
      {
        name: 'Brazil Grå 50',
        code: 'brazil-gra-50',
        type: 'kitchen-worktop',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/brazil-gra-50.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/brazil-gra-50-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'brazil-gra-50.jpg;brazil-gra-50-80x80.jpg',
        },
      },
      {
        name: 'Concrete Grå 40',
        code: 'concrete-gra-40',
        type: 'kitchen-worktop',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/concrete-gra-40.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/concrete-gra-40-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'concrete-gra-40.jpg;concrete-gra-40-80x80.jpg',
        },
      },
      {
        name: 'Midgrey Grå 43',
        code: 'midgrey-gra-43',
        type: 'kitchen-worktop',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/midgrey-gra-43.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/midgrey-gra-43-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'midgrey-gra-43.jpg;midgrey-gra-43-80x80.jpg',
        },
      },
      {
        name: 'Vit Grå 51',
        code: 'vit-gra-51',
        type: 'kitchen-worktop',
        brand: '',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/vit-gra-51.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/kitchen-worktop/vit-gra-51-80x80.jpg',
        details: {
          'vendor-name': '',
          'img-names': 'vit-gra-51.jpg;vit-gra-51-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Golv/Vinyl',
    measure: 'Utbyte',
    data: [
      {
        name: 'Gea, Light Natural',
        code: '3532 029',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Gea Light Natural vi3532029.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Gea Light Natural vi3532029-80x80.jpg',
        details: {
          category: 'vinyl-flooring',
          color: 'Light Natural',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Gea Light Natural vi3532029.jpg;Gea Light Natural vi3532029-80x80.jpg',
        },
      },
      {
        name: 'Kiruma Grey',
        code: '3532 042',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Kiruma Grey i3532042.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Kiruma Grey i3532042-80x80.jpg',
        details: {
          category: 'vinyl-flooring',
          color: 'Grey',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Kiruma Grey i3532042.jpg;Kiruma Grey i3532042-80x80.jpg',
        },
      },
      {
        name: 'Selene, Grey',
        code: '3532 035',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Selene Grey vi3532035.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Selene Grey vi3532035-80x80.jpg',
        details: {
          category: 'vinyl-flooring',
          color: 'Grey',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Selene Grey vi3532035.jpg;Selene Grey vi3532035-80x80.jpg',
        },
      },
      {
        name: 'Selene, Light Grey',
        code: '3532 034',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Selene Light Grey vi3532034.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Selene Light Grey vi3532034-80x80.jpg',
        details: {
          category: 'vinyl-flooring',
          color: 'Light Grey',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Selene Light Grey vi3532034.jpg;Selene Light Grey vi3532034.jpg-80x80.jpg',
        },
      },
      {
        name: 'Wild Oak, Light',
        code: '3532 044',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Wild Oak Light vi3532044.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/vinyl-flooring/Wild Oak Light vi3532044-80x80.jpg',
        details: {
          category: 'vinyl-flooring',
          color: 'Light',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Wild Oak Light vi3532044.jpg;Wild Oak Light vi3532044-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Golv/Laminate',
    measure: 'Utbyte',
    data: [
      {
        name: 'Ek',
        code: 'Ek',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Ek laek.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Ek laek-80x80.jpg',
        details: {
          category: 'laminate',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Ek laek.jpg;Ek laek-80x80.jpg',
        },
      },
      {
        name: 'Lönn lalonn',
        code: 'Lönn lalonn',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Lönn lalonn.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Lönn lalonn-80x80.jpg',
        details: {
          category: 'laminate',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Lönn lalonn.jpg;Lönn lalonn-80x80.jpg',
        },
      },
      {
        name: 'Vitkalkad ask laask',
        code: 'Vitkalkad ask laask',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Vitkalkad ask laask.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Vitkalkad ask laask-80x80.jpg',
        details: {
          category: 'laminate',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Vitkalkad ask laask.jpg;Vitkalkad ask laask-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Golv/Parkett',
    measure: 'Utbyte',
    data: [
      {
        name: 'Ek, 3-stav',
        code: 'Ek, 3-stav',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/parquet/Ek 3-stav paek.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/parquet/Ek 3-stav paek-80x80.jpg',
        details: {
          category: 'parquet',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Ek 3-stav paek.jpg;Ek 3-stav paek-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Kök',
    unitName: 'Väggar',
    measure: 'Målas',
    data: [
      {
        name: 'IN 700',
        code: '700',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-700.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-700-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-700.jpg;IN-700-80x80.jpg',
        },
      },
      {
        name: 'IN 702',
        code: '702',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-702.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-702-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-702.jpg;IN-702-80x80.jpg',
        },
      },
      {
        name: 'IN 710',
        code: '710',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-710.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-710-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-710.jpg;IN-710-80x80.jpg',
        },
      },
      {
        name: 'IN 711',
        code: '711',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-711.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-711-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-711.jpg;IN-711-80x80.jpg',
        },
      },
      {
        name: 'IN 721',
        code: '721',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-721.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-721-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-721.jpg;IN-721-80x80.jpg',
        },
      },
      {
        name: 'IN 735',
        code: '735',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-735.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-735-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-735.jpg;IN-735-80x80.jpg',
        },
      },
      {
        name: 'IN 746',
        code: '746',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-746.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-746-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-746.jpg;IN-746-80x80.jpg',
        },
      },
      {
        name: 'IN 751',
        code: '751',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-751.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-751-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-751.jpg;IN-751-80x80.jpg',
        },
      },
      {
        name: 'IN 768',
        code: '768',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-768.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-768-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-768.jpg;IN-768-80x80.jpg',
        },
      },
      {
        name: 'IN 778',
        code: '778',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-778.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-778-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-778.jpg;IN-778-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Sovrum',
    unitName: 'Golv/Linolium',
    measure: 'Utbyte',
    data: [
      {
        name: 'Grå Tarkett, Veneto',
        code: '604',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li604.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li604-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li604.jpg;li604-80x80.jpg',
        },
      },
      {
        name: 'Ljus grå Tarkett, Veneto',
        code: '702',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li702.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li702-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li702.jpg;li702-80x80.jpg',
        },
      },
      {
        name: 'Grå sand Tarkett, Veneto',
        code: '793',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li793.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li793-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li793.jpg;li793-80x80.jpg',
        },
      },
      {
        name: 'Mörk grå Tarkett, Veneto',
        code: '906',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li906.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li906-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li906.jpg;li906-80x80.jpg',
        },
      },
      {
        name: 'Almond Tarkett, Veneto',
        code: '637',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-637-almond.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-637-almond-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: 'Almond',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'linoleum-637-almond.jpg;linoleum-637-almond-80x80.jpg',
        },
      },
      {
        name: 'Ecru Tarkett, Veneto',
        code: '711',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-711-ecru.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-711-ecru-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'linoleum-711-ecru.jpg;linoleum-711-ecru-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Sovrum',
    unitName: 'Golv/Laminate',
    measure: 'Utbyte',
    data: [
      {
        name: 'Ek',
        code: 'Ek',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Ek laek.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Ek laek-80x80.jpg',
        details: {
          category: 'laminate',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Ek laek.jpg;Ek laek-80x80.jpg',
        },
      },
      {
        name: 'Lönn lalonn',
        code: 'Lönn lalonn',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Lönn lalonn.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Lönn lalonn-80x80.jpg',
        details: {
          category: 'laminate',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Lönn lalonn.jpg;Lönn lalonn-80x80.jpg',
        },
      },
      {
        name: 'Vitkalkad ask laask',
        code: 'Vitkalkad ask laask',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Vitkalkad ask laask.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/laminate/Vitkalkad ask laask-80x80.jpg',
        details: {
          category: 'laminate',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Vitkalkad ask laask.jpg;Vitkalkad ask laask-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Sovrum',
    unitName: 'Golv/Parkett',
    measure: 'Utbyte',
    data: [
      {
        name: 'Ek, 3-stav',
        code: 'Ek, 3-stav',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/parquet/Ek 3-stav paek.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/parquet/Ek 3-stav paek-80x80.jpg',
        details: {
          category: 'parquet',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Ek 3-stav paek.jpg;Ek 3-stav paek-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Sovrum',
    unitName: 'Väggar/Målade',
    measure: 'Målas',
    data: [
      {
        name: 'IN 700',
        code: '700',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-700.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-700-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-700.jpg;IN-700-80x80.jpg',
        },
      },
      {
        name: 'IN 702',
        code: '702',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-702.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-702-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-702.jpg;IN-702-80x80.jpg',
        },
      },
      {
        name: 'IN 710',
        code: '710',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-710.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-710-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-710.jpg;IN-710-80x80.jpg',
        },
      },
      {
        name: 'IN 711',
        code: '711',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-711.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-711-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-711.jpg;IN-711-80x80.jpg',
        },
      },
      {
        name: 'IN 721',
        code: '721',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-721.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-721-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-721.jpg;IN-721-80x80.jpg',
        },
      },
      {
        name: 'IN 735',
        code: '735',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-735.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-735-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-735.jpg;IN-735-80x80.jpg',
        },
      },
      {
        name: 'IN 746',
        code: '746',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-746.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-746-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-746.jpg;IN-746-80x80.jpg',
        },
      },
      {
        name: 'IN 751',
        code: '751',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-751.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-751-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-751.jpg;IN-751-80x80.jpg',
        },
      },
      {
        name: 'IN 768',
        code: '768',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-768.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-768-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-768.jpg;IN-768-80x80.jpg',
        },
      },
      {
        name: 'IN 778',
        code: '778',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-778.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-778-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-778.jpg;IN-778-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Vardagsrum',
    unitName: 'Golv/Linolium',
    measure: 'Utbyte',
    data: [
      {
        name: 'Grå Tarkett, Veneto',
        code: '604',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li604.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li604-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li604.jpg;li604-80x80.jpg',
        },
      },
      {
        name: 'Ljus grå Tarkett, Veneto',
        code: '702',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li702.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li702-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li702.jpg;li702-80x80.jpg',
        },
      },
      {
        name: 'Grå sand Tarkett, Veneto',
        code: '793',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li793.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li793-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li793.jpg;li793-80x80.jpg',
        },
      },
      {
        name: 'Mörk grå Tarkett, Veneto',
        code: '906',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li906.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/li906-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'li906.jpg;li906-80x80.jpg',
        },
      },
      {
        name: 'Almond Tarkett, Veneto',
        code: '637',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-637-almond.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-637-almond-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: 'Almond',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'linoleum-637-almond.jpg;linoleum-637-almond-80x80.jpg',
        },
      },
      {
        name: 'Ecru Tarkett, Veneto',
        code: '711',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-711-ecru.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/linoleum-floor/linoleum-711-ecru-80x80.jpg',
        details: {
          category: 'linoleum-floor',
          color: '',
          'vendor-name': '',
          rooms: [
            'bedroom',
            'living-room',
          ],
          'img-names': 'linoleum-711-ecru.jpg;linoleum-711-ecru-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Vardagsrum',
    unitName: 'Golv/Parkett',
    measure: 'Utbyte',
    data: [
      {
        name: 'Ek, 3-stav',
        code: 'Ek, 3-stav',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/parquet/Ek 3-stav paek.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/parquet/Ek 3-stav paek-80x80.jpg',
        details: {
          category: 'parquet',
          color: '',
          'vendor-name': '',
          rooms: [
            'kitchen',
          ],
          'img-names': 'Ek 3-stav paek.jpg;Ek 3-stav paek-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Vardagsrum',
    unitName: 'Väggar/Målade',
    measure: 'Målas',
    data: [
      {
        name: 'IN 700',
        code: '700',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-700.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-700-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-700.jpg;IN-700-80x80.jpg',
        },
      },
      {
        name: 'IN 702',
        code: '702',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-702.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-702-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-702.jpg;IN-702-80x80.jpg',
        },
      },
      {
        name: 'IN 710',
        code: '710',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-710.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-710-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-710.jpg;IN-710-80x80.jpg',
        },
      },
      {
        name: 'IN 711',
        code: '711',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-711.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-711-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-711.jpg;IN-711-80x80.jpg',
        },
      },
      {
        name: 'IN 721',
        code: '721',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-721.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-721-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-721.jpg;IN-721-80x80.jpg',
        },
      },
      {
        name: 'IN 735',
        code: '735',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-735.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-735-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-735.jpg;IN-735-80x80.jpg',
        },
      },
      {
        name: 'IN 746',
        code: '746',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-746.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-746-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-746.jpg;IN-746-80x80.jpg',
        },
      },
      {
        name: 'IN 751',
        code: '751',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-751.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-751-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-751.jpg;IN-751-80x80.jpg',
        },
      },
      {
        name: 'IN 768',
        code: '768',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-768.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-768-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-768.jpg;IN-768-80x80.jpg',
        },
      },
      {
        name: 'IN 778',
        code: '778',
        type: 'colors',
        brand: 'IN',
        price: null,
        description: '',
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-778.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/colors/IN-778-80x80.jpg',
        details: {
          rooms: [
            'bedroom',
            'kitchen',
            'living-room',
          ],
          category: 'wall-painting',
          'vendor-name': 'IN',
          'img-names': 'IN-778.jpg;IN-778-80x80.jpg',
        },
      },
    ],
  },
  {
    typeName: 'Trappa',
    unitName: 'Golv',
    measure: 'Utbyte',
    data: [
      {
        name: 'Svart, Golvabia Stepp',
        code: '208350',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/staircase/208350.jpg',
        ],
        scaledImageUrl: 'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/staircase/208350-80x80.jpg',
        details: {
          category: 'staircase',
          color: '',
          'vendor-name': '',
          rooms: [
            'living-room',
          ],
          'img-names': '208350.jpg;208350-80x80.jpg',
        },
      },
      {
        name: 'Grå, Golvabia Stepp',
        code: '208370',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/staircase/208370.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/staircase/208370-80x80.jpg',
        details: {
          category: 'staircase',
          color: '',
          'vendor-name': '',
          rooms: [
            'living-room',
          ],
          'img-names': '208370.jpg;208370-80x80.jpg',
        },
      },
      {
        name: 'Ljusbeige, Golvabia Stepp',
        code: '208390',
        type: 'floors',
        brand: '',
        price: null,
        description: null,
        imageUrls: [
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/staircase/208390.jpg',
        ],
        scaledImageUrl:
          'https://assets-dev.boportalen.brikks.io/lkf/maintenance/images/floors/staircase/208390-80x80.jpg',
        details: {
          category: 'staircase',
          color: '',
          'vendor-name': '',
          rooms: [
            'living-room',
          ],
          'img-names': '208390.jpg;208390-80x80.jpg',
        },
      },
    ],
  },
];
