import React, { useState } from 'react';
import { WaterSavingModal } from 'components/brikks/sustainability/WaterSaving/components/WaterSavingModal';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { Brikk } from 'components/Brikk';
import { WaterSavingIcon } from 'assets/icons/brikks/WaterSavingIcon';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { WaterSavingPreview } from 'components/brikks/sustainability/WaterSaving/components/WaterSavingPreview';

export const WaterSaving = ({ position, code, status, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<WaterSavingIcon />}
            heading="waterSavingBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content center>
            <WaterSavingPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="waterSaving"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="waterSaving"
        onClose={() => setIsModalOpen(false)}
      >
        <WaterSavingModal />
      </BrikkModal>
    </>
  );
};
