import React from 'react';
import { Text } from 'components/typography';
import { Input } from 'components/form-elements/inputs/Input';
import styles from './styles.module.scss';

export const BankIdSecondSignature = ({ value, onChange, isError }) => (
  <div className={styles.secondSignature}>
    <Text
      className={styles.description}
      intl="secondSignature"
      size={14}
    />

    <div className={styles.action}>
      <Input
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      {isError && (
        <Text
          intl="invalidEmail"
          error
        />
      ) }
    </div>
  </div>
);
