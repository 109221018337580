import React, { useState } from 'react';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { Brikk } from 'components/Brikk';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { CleaningAndMaintenanceModal } from 'components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal';
import { CleaningSprayIcon } from 'assets/icons/brikks/CleaningSprayIcon';
import { CleaningAndMaintenancePreview } from 'components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenancePreview';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';

export const CleaningAndMaintenance = ({ position, code, status, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          status={status}
          name={name}
        >
          <Brikk.Header
            icon={<CleaningSprayIcon />}
            heading="cleaningAndMaintenanceBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <CleaningAndMaintenancePreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="cleaningAndMaintenance"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Cleaning and maintenance info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="cleaningAndMaintenance"
        onClose={() => setIsModalOpen(false)}
      >
        <CleaningAndMaintenanceModal />
      </BrikkModal>
    </>
  );
};
