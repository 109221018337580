import React, { useContext } from 'react';
import { MovingOutModalContext } from 'components/brikks/home/MovingOut/components/MovingOutModal/index';
import { Agreements } from
  'components/brikks/home/MovingOut/components/MovingOutModal/components/MovingOutInfo/components/CheckListTab/Agreements';

// Third column of the modal
export const MenuContent = () => {
  const { activeMenu } = useContext(MovingOutModalContext);

  const ContentComp = activeMenu;

  if (!ContentComp) {
    return null;
  }
  return activeMenu === 'terminateAgreements' ? <Agreements /> : null;
};
