/* eslint-disable max-len */
import brikksLogoWhite from 'assets/media/brikks/logo-white.svg';
import brikksLogoDark from 'assets/media/brikks/logo-dark.svg';
import brikksFavicon from 'assets/media/brikks/favicon.ico';
import lkfWelcome from 'assets/media/lkf/welcome.png';
import vidingehemLogoDark from 'assets/media/vidingehem/logo-dark.svg';
import riksLogo from 'assets/media/rib/logo-white.svg';
import lkfLogoWhite from 'assets/media/lkf/logo-white.svg';
import riksBrfLogo from 'assets/media/riksBrf/logo.png';
import nbabLogo from 'assets/media/nyr/nbab-logo.png';
import nyrELogo from 'assets/media/nyr/nybro-energi.png';
import svalLogo from 'assets/media/sval/logo.png';
import brfKarLogo from 'assets/media/brfKar/logo.png';
import nyrLogo from 'assets/media/nyr/logo-nybro-kommun.svg';
import vxjLogoDark from 'assets/media/vxj/logo-dark.svg';
import wxtLogoWhite from 'assets/media/wxt/logo-white.svg';
import sfgLogoWhite from 'assets/media/sfg/logo-white.svg';
import heimLogo from 'assets/media/heim/heim-logo.svg';
import helsinbLogo from 'assets/media/hlsb/logo.svg';
import tornLogoDark from 'assets/media/torn/torn-logo-dark.svg';
import h22Logo from 'assets/media/h22/logo-white.svg';
import lundLogo from 'assets/media/lund/logo.png';
import lundBrunnLogo from 'assets/media/lund/lundBrunn-logo.png';
import malmoLogo from 'assets/media/malmo-stad/malmo-logo.png';
import kftLogo from 'assets/media/kft/kft-logo.png';
import stadMalmoLogo from 'assets/media/stdmalmo/logo-white.svg';
import mdcLogo from 'assets/media/mdc/mdc-logo.svg';
import grtLogo from 'assets/media/grt/logo-preview.png';
import granBrfLogo from 'assets/media/granBrf/logo.png';
import ikaLogo from 'assets/media/ika/logo.png';
import icaLogo from 'assets/media/ica/logo.png';
import dreLogo from 'assets/media/dre/logo.png';
import croLogo from 'assets/media/cro/logo.png';
import brfLogo from 'assets/media/brf/logo.png';
import brfSoloLogo from 'assets/media/brf/brf-solo-logo.png';
import jernhusenLogo from 'assets/media/demo/jernhusen-logo.png';
import skurupshemLogo from 'assets/media/demo/skurupshem-logo.png';
import sjubohemLogo from 'assets/media/demo/sjubohem-logo.png';
import oboLogo from 'assets/media/demo/obo-logo.png';
import { OrderMode } from 'components/headers/AppHeader/components/OrderMode';
import { ClientTheme } from 'components/menus/SideMenu/components/ClientTheme';
import { BrikksLivingInfo } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/BrikksLivingInfo';
import brikksThemes from 'global/tenant-themes/brikks-themes.json';
import { Tenants } from 'global/tenant-config/tenants';
import vxbThemes from 'global/tenant-themes/vxb-themes.json';
import lkfThemes from 'global/tenant-themes/lkf-themes.json';
import riksThemes from 'global/tenant-themes/riks-themes.json';
import riksBrfThemes from 'global/tenant-themes/riksBrf-themes.json';
import nbabThemes from 'global/tenant-themes/nbab-themes.json';
import nybroEnergieThemes from 'global/tenant-themes/nyr-e-themes.json';
import nybroThemes from 'global/tenant-themes/nyr-themes.json';
import svalThemes from 'global/tenant-themes/sval-themes.json';
import brfKarThemes from 'global/tenant-themes/brfKar-themes.json';
import wxtThemes from 'global/tenant-themes/wxt-themes.json';
import vxjThemes from 'global/tenant-themes/vxj-themes.json';
import sfgThemes from 'global/tenant-themes/sfg-themes.json';
import sfgBlueThemes from 'global/tenant-themes/sfg-blue-themes.json';
import heimThemes from 'global/tenant-themes/heim-themes.json';
import tornThemes from 'global/tenant-themes/torn-themes.json';
import h22Themes from 'global/tenant-themes/h22-themes.json';
import helsinThemes from 'global/tenant-themes/helsin-themes.json';
import lundThemes from 'global/tenant-themes/lund-themes.json';
import lundBrunnThemes from 'global/tenant-themes/lundBrunn-themes.json';
import malmoThemes from 'global/tenant-themes/malmo-themes.json';
import stdMalmoThemes from 'global/tenant-themes/stdmalmo-themes.json';
import kftThemes from 'global/tenant-themes/kft-themes.json';
import mdcThemes from 'global/tenant-themes/mdc-themes.json';
import grtThemes from 'global/tenant-themes/grt-themes.json';
import granBrfThemes from 'global/tenant-themes/granBrf-themes.json';
import ikaThemes from 'global/tenant-themes/ika-themes.json';
import icaThemes from 'global/tenant-themes/ica-themes.json';
import dreThemes from 'global/tenant-themes/dre-themes.json';
import croThemes from 'global/tenant-themes/cro-themes.json';
import brfThemes from 'global/tenant-themes/brf-themes.json';
import brfSoloThemes from 'global/tenant-themes/brfSolo-themes.json';
import jernhusenThemes from 'global/tenant-themes/jernhusen-themes.json';
import sjobohemThemes from 'global/tenant-themes/sjobohem-themes.json';
import skurupshemThemes from 'global/tenant-themes/skurupshem-themes.json';
import oboThemes from 'global/tenant-themes/obo-themes.json';
import { LKFOnboarding } from 'pages/onboarding/LKFOnboarding';
import Order from 'pages/Order';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import {
  BLUE_BG, BRF1_BG, CRO_BG, DRE_BG, GRT_BG,
  HEIM_BG, JERNHUSEN_BG, LIGHT_GRAY_BG, LUND_BG,
  SFG_BG,
  SFG_GREEN_BG, SHURUPSHEM_BG,
  SVAL_BG,
  TORNET_BG,
  WHITE_BG,
  WXT_NATURE_BG,
} from 'services/user/settings/utils';
import brikksEnglish from 'translations/brikks/english.json';
import brikksSwedish from 'translations/brikks/swedish.json';

export const brikksTenant = {
  assets: {
    logoWhite: brikksLogoWhite,
    logoDark: brikksLogoDark,
    favicon: brikksFavicon,
    welcome: lkfWelcome,
  },
  intl: {
    english: brikksEnglish,
    swedish: brikksSwedish,
  },
  components: {
    onboarding: LKFOnboarding,
    orderMode: OrderMode,
    order: Order,
    clientTheme: ClientTheme,
  },
  initialBackground: WHITE_BG,
  themes: brikksThemes,
  additionalThemes: {
    [Tenants.BRIKKS.tenantId]: {
      name: 'brikks',
      theme: brikksThemes,
      logo: brikksLogoDark,
      background: WHITE_BG,
    },
    [Tenants.VXB.tenantId]: {
      name: 'vidingehem',
      theme: vxbThemes,
      logo: vidingehemLogoDark,
      background: WHITE_BG,
    },
    [Tenants.LKF.tenantId]: {
      name: 'lundsKommunsFastighets',
      theme: lkfThemes,
      logo: lkfLogoWhite,
      background: WHITE_BG,
    },
    riks: {
      name: 'riks',
      theme: riksThemes,
      logo: riksLogo,
      background: TORNET_BG,
    },
    riksBrf: {
      name: 'riksBrf',
      theme: riksBrfThemes,
      logo: riksBrfLogo,
      background: TORNET_BG,
    },
    nbab: {
      name: 'nbab',
      theme: nbabThemes,
      logo: nbabLogo,
      background: HEIM_BG,
    },
    nyrE: {
      name: 'nybroEnergie',
      theme: nybroEnergieThemes,
      logo: nyrELogo,
      background: TORNET_BG,
    },
    nyr: {
      name: 'nybroKommun',
      theme: nybroThemes,
      logo: nyrLogo,
      background: TORNET_BG,
    },
    sval: {
      name: 'svalKommun',
      theme: svalThemes,
      logo: svalLogo,
      background: SVAL_BG,
    },
    brfKar: {
      name: 'brfKarnevalen',
      theme: brfKarThemes,
      logo: brfKarLogo,
      background: TORNET_BG,
    },
    wxt: {
      name: 'wexnet',
      theme: wxtThemes,
      logo: wxtLogoWhite,
      background: WXT_NATURE_BG,
    },
    vxj: {
      name: 'vaxjoKommun',
      theme: vxjThemes,
      logo: vxjLogoDark,
      background: WHITE_BG,
    },
    sfg: {
      name: 'SFGSolid',
      theme: sfgThemes,
      logo: sfgLogoWhite,
      background: SFG_GREEN_BG,
    },
    sfgBlue: {
      name: 'SFGTransparent',
      theme: sfgBlueThemes,
      logo: sfgLogoWhite,
      background: SFG_BG,
    },
    heim: {
      name: 'heimstaden',
      theme: heimThemes,
      logo: heimLogo,
      background: HEIM_BG,
    },
    torn: {
      name: 'tornet',
      theme: tornThemes,
      logo: tornLogoDark,
      background: TORNET_BG,
    },
    h22: {
      name: 'h22',
      theme: h22Themes,
      logo: h22Logo,
      background: BLUE_BG,
    },
    hlsb: {
      name: 'helsingburg',
      theme: helsinThemes,
      logo: helsinbLogo,
      background: LIGHT_GRAY_BG,
    },
    lund: {
      name: 'lund',
      theme: lundThemes,
      logo: lundLogo,
      background: LIGHT_GRAY_BG,
    },
    lundBrunn: {
      name: 'lundBrunn',
      theme: lundBrunnThemes,
      logo: lundBrunnLogo,
      background: LUND_BG,
    },
    malmo: {
      name: 'malmoStadt',
      theme: malmoThemes,
      logo: malmoLogo,
      background: LIGHT_GRAY_BG,
    },
    stdmalmo: {
      name: 'stadsfiberMalmo',
      theme: stdMalmoThemes,
      logo: stadMalmoLogo,
      background: SFG_GREEN_BG,
    },
    kft: {
      name: 'kraftringen',
      theme: kftThemes,
      logo: kftLogo,
      background: TORNET_BG,
    },
    mdc: {
      name: 'midroc',
      theme: mdcThemes,
      logo: mdcLogo,
      background: HEIM_BG,
    },
    grt: {
      name: 'grt',
      theme: grtThemes,
      logo: grtLogo,
      background: GRT_BG,
    },
    granBrf: {
      name: 'granBrf',
      theme: granBrfThemes,
      logo: granBrfLogo,
      background: GRT_BG,
    },
    ika: {
      name: 'ikano',
      theme: ikaThemes,
      logo: ikaLogo,
      background: TORNET_BG,
    },
    ica: {
      name: 'ica',
      theme: icaThemes,
      logo: icaLogo,
      background: TORNET_BG,
    },
    dre: {
      name: 'dre',
      theme: dreThemes,
      logo: dreLogo,
      background: DRE_BG,
    },
    cro: {
      name: 'cro',
      theme: croThemes,
      logo: croLogo,
      background: CRO_BG,
    },
    brf: {
      name: 'brf',
      theme: brfThemes,
      logo: brfLogo,
      background: BRF1_BG,
    },
    brfSolo: {
      name: 'brfSolo',
      theme: brfSoloThemes,
      logo: brfSoloLogo,
      background: BRF1_BG,
    },
    jernhusen: {
      name: 'jernhusen',
      theme: jernhusenThemes,
      logo: jernhusenLogo,
      background: JERNHUSEN_BG,
    },
    skurupshem: {
      name: 'skurupshem',
      theme: skurupshemThemes,
      logo: skurupshemLogo,
      background: SHURUPSHEM_BG,
    },
    sjobohem: {
      name: 'sjobohem',
      theme: sjobohemThemes,
      logo: sjubohemLogo,
      background: WHITE_BG,
    },
    obo: {
      name: 'obo',
      theme: oboThemes,
      logo: oboLogo,
      background: WHITE_BG,
    },
  },
  gridThemes: {
    [MultiGrid.Society]: {
      theme: vxjThemes,
      logo: vxjLogoDark,
      condition: {
        tenantThemeIsIn: [Tenants.VXB.tenantId, 'wxt'],
      },
    },
    [MultiGrid.Care]: {
      theme: vxjThemes,
      logo: vxjLogoDark,
      condition: {
        tenantThemeIsIn: [Tenants.VXB.tenantId, 'wxt'],
      },
    },
  },
  sizes: {
    brikk: '300px',
    'brikks-spacing': '12px',
  },
  multiGrids: [MultiGrid.Home, MultiGrid.Neighbourhood, MultiGrid.Society, MultiGrid.Sustainability, MultiGrid.Smartness, MultiGrid.Care, MultiGrid.Finances, MultiGrid.Store],
  brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A13', 'A14', 'A15', 'A16', 'A18', 'A19', 'B01', 'B02', 'B03', 'B04', 'B05', 'B06', 'B07', 'B08', 'B09', 'B10', 'B11', 'B12', 'C01A', 'C01B', 'C02A', 'C02B', 'C02C', 'C02D', 'C02E', 'C02F', 'C03A', 'C03B', 'C04', 'C05A', 'C05B', 'C06', 'C07', 'C08', 'C09', 'C10', 'D01', 'D02A', 'D02B', 'D03', 'D04A', 'D04B', 'D05A', 'D05B', 'D05C', 'D05D', 'D06A', 'D06B', 'D07', 'D08', 'D09', 'D10', 'D11', 'E01A', 'E01B', 'E02', 'E03', 'E04A', 'E04B', 'E05', 'E06A', 'E06B', 'E06C', 'E07A', 'E07B', 'E08', 'E11', 'E12A', 'E12B', 'E12C', 'E13', 'F01A', 'F01B', 'F02', 'F03', 'F04', 'F05', 'F06', 'F07', 'F08', 'F09', 'F10', 'F11', 'F12', 'F13', 'F14', 'F15', 'G01', 'G02', 'G03', 'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13', 'G14', 'H01', 'H02', 'H01', 'H02', 'H01', 'H02', 'H01', 'H02', 'H03', 'H04', 'H05', 'H06', 'H07', 'H08', 'H09', 'H10', 'H11', 'H12', 'H13', 'H14', 'H20', 'H21', 'H22', 'H23'],
  city: 'Lund',
  openweathermapLat: '55.7058',
  openweathermapLon: '13.1932',
  officeLocation: 'https://www.hitta.se/kartan!~55.7005386595884,13.195187103290397,16z/geocode!l=55.7005386595884:13.195187103290397!z=16',
  housingQueue: {
    link: 'https://www.lkf.se/att-hyra-hos-oss/boko-och-kopoang/',
  },
  myHome: {
    components: {
      livingInfo: BrikksLivingInfo,
    },
  },
  rpName: 'viaeuropa-sverige',
  gaTracking: '',
};
