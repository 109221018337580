import { takeLatest, call, delay, put } from 'redux-saga/effects';
import { BankIDSigningActions } from 'services/auth/bank-id-signing/actions';
import { BankIDLoginActions } from '../bank-id-login/actions';
import { cancelBankIDOrderAPI, checkBankIDOrderStatus } from './api';
import { bankIDOrderFail, BankIDOrderStatusActions, bankIDOrderSuccessful, bankIDUpdateOrderStatus } from './actions';

export function* checkOrderStatus({ payload }) {
  try {
    while (true) {
      yield delay(2000);
      const response = yield call(checkBankIDOrderStatus, payload);

      if (response.status === 'pending') {
        switch (response.hintCode) {
          case 'outstandingTransaction':
          case 'noClient':
            yield put(bankIDUpdateOrderStatus('bankID-RFA1'));
            break;
          case 'started':
            yield put(bankIDUpdateOrderStatus('bankID-RFA15'));
            break;
          case 'userSign':
            yield put(bankIDUpdateOrderStatus('bankID-RFA9'));
            break;
          default:
            yield put(bankIDUpdateOrderStatus('bankID-RFA21'));
            break;
        }
      }

      if (response.status === 'complete') {
        if (response.httpStatusCode) {
          throw new Error('genericErrorMessage');
        }

        yield put(bankIDOrderSuccessful({ response, orderRef: payload }));
        return;
      }

      if (response.status === 'failed') {
        switch (response.hintCode) {
          case 'expiredTransaction':
            yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA8' }));
            break;
          case 'certificateErr':
            yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA16' }));
            break;
          case 'userCancel':
            yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA6' }));
            break;
          case 'cancelled':
            yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA3' }));
            break;
          case 'startFailed':
            yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA17' }));
            break;
          default:
            yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA22' }));
            break;
        }
        return;
      }
    }
  } catch (e) {
    if (e.errorCode) {
      switch (e.errorCode) {
        case 'alreadyInProgress':
          yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA4' }));
          break;
        case 'invalidParameters':
          yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA3' }));
          break;
        default:
          yield put(bankIDOrderFail({ orderRef: payload, error: 'bankID-RFA22' }));
          break;
      }
    } else {
      yield put(bankIDOrderFail({ orderRef: payload, error: e.message }));
    }
  }
}

function* cancelBankIDOrder({ payload }) {
  yield call(cancelBankIDOrderAPI, payload);
}

export const bankIDOrderStatusEffects = [
  takeLatest(BankIDSigningActions.BANK_ID_SIGNING_INIT_SUCCESS, checkOrderStatus),
  takeLatest(BankIDLoginActions.BANK_ID_LOGIN_INIT_SUCCESS, checkOrderStatus),
  takeLatest(BankIDOrderStatusActions.BANK_ID_ORDER_CANCEL, cancelBankIDOrder),
];
