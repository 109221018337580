import React from 'react';
import styles from './styles.module.scss';

export const ProcessingLoader = () => (
  <div className={styles.loader}>
    <div />
    <div />
    <div />
    <div />
  </div>
);
