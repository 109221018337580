import React, { useContext, useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { PrevStep } from 'components/form-elements/buttons/PrevStep';
import { MultiStepContext } from 'components/MultiStep';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { Brikk } from 'components/Brikk';
import { Text } from 'components/typography/Text';
import { UpgradeCost } from
  'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeSummary/components/UpgradeCost';
import { UpgradeOrder } from
  'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeSummary/components/UpgradeOrder';
import { StepProgress } from
  'components/brikks/home/Upgrade/components/UpgradeModal/components/StepProgress';
import { useIntl } from 'react-intl';
import { BankIDMultipleSigning } from 'components/bank-id/BankIDMultipleSigning';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/Checkout/styles.module.scss';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { ExternalLink } from 'components/links/ExternalLink';

export const Checkout = () => {
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  const { onPrevStep } = useContext(MultiStepContext);
  const { selectedPurchases, setSelectedPurchases } = useContext(UpgradeContext);
  const intl = useIntl();

  return (
    <div>
      <Grid brikkLayout>
        <Grid.Column fullHeight>
          <Brikk.Heading heading="summaryYourOrder">
            <UpgradeCost
              selectedPurchases={selectedPurchases}
              setSelectedPurchases={setSelectedPurchases}
            />
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Text
            intl="upgradeInfo"
            element="span"
            size={16}
            intlValues={{
              italic: msg => (
                <Text
                  fontStyle="italic"
                  size={15}
                  brown
                >
                  {msg}
                </Text>
              ),
              h2: (...chunks) => (
                <Text
                  element="h2"
                  size={22}
                  uppercase
                >
                  {chunks}
                </Text>
              ),
            }}
          />
        </Grid.Column>

        <Grid.Column fullHeight>
          {isOrderSubmitted ? (
            <Text
              intl="maintenanceOrderConfirmation"
              size={16}
            />
          ) : (
            <Brikk.Heading heading="signature">
              <Text
                intl="signatureDescription"
                size={14}
              />

              <div className={styles.bankId}>
                <BankIDMultipleSigning
                  showConfiramtionMessage
                  userVisibleData={intl.formatMessage({ id: 'maintenanceOrderBankIDSignUserData' })}
                  disabled={!Object.keys(selectedPurchases).length}
                >
                  <UpgradeOrder
                    maintenanceOrder={selectedPurchases}
                    onConfirmOrder={() => setIsOrderSubmitted(true)}
                  />
                </BankIDMultipleSigning>
              </div>

              <Text
                intl="signatureInfo"
                size={14}
              />

              <div className={styles.moreInfo}>
                <ExternalLink href={intl.formatMessage({ id: 'bankIDSigningLink' })}>
                  <ProceedButton>
                    <ProceedButton.Button intl="moreInfo" />
                    <ProceedButton.SignIn />
                  </ProceedButton>
                </ExternalLink>
              </div>
            </Brikk.Heading>
          )}
        </Grid.Column>
      </Grid>

      <PrevStep onClick={onPrevStep} />
      <StepProgress />
    </div>
  );
};
