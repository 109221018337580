import React from 'react';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { Text } from 'components/typography/Text';
import cleaningImg from 'assets/media/cleaning-and-maintenance.png';

export const CleaningAndMaintenancePreview = () => (
  <>
    <CircularImage
      src={cleaningImg}
      alt="Cleaning"
    />
    <Text
      center
      size={14}
      uppercase
      intl="cleaningAndMaintenance"
    />
  </>
);
