export const CheckListOptions = {
  GENERAL_CLEANING: {
    menu: 'generalCleaning',
  },
  BALCONY_AND_TERRACE: {
    menu: 'balconyAndTerrace',
  },
  KITCHEN: {
    menu: 'kitchen',
    menuDetails: 'kitchenContent',
  },
  BATHROOM: {
    menu: 'bathroom',
    menuDetails: 'bathroomContent',
  },
  STORAGE: {
    menu: 'storage',
  },
  RECYCLING: {
    menu: 'recycling',
  },
};
