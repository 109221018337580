import React, { useEffect } from 'react';
import { useSubmitMaintenanceOrder } from 'services/maintenance/submit-maintenance-order/hook';
import { Text } from 'components/typography/Text';
import Loader from 'components/loaders/Loader';
import { ShouldRender } from 'components/ShouldRender';
import { PrimaryButton } from 'components/form-elements/buttons/PrimaryButton';
import { Button } from 'components/form-elements/buttons/Button';
import styles from './styles.module.scss';

export const UpgradeOrder = ({ orderRef, maintenanceOrder, onClose, onConfirmOrder }) => {
  const { isSuccessful, error, loading, onSubmitOrder } = useSubmitMaintenanceOrder();

  useEffect(() => {
    onSubmitOrder(orderRef, maintenanceOrder);
  }, [onSubmitOrder, orderRef, maintenanceOrder]);

  const confirmOrder = () => {
    onClose();
    onConfirmOrder();
  };

  return (
    <div className={styles.maintenanceOrder}>
      {loading ? (
        <div className={styles.loading}>
          <Text
            center
            intl="maintenanceOrderLoading"
            size={16}
          />

          <Loader />
        </div>
      ) : (
        <ShouldRender condition={isSuccessful}>
          <div className={styles.success}>
            <Text
              center
              intl="maintenanceOrderSuccess"
              size={16}
            />

            <PrimaryButton
              onClick={confirmOrder}
              name="close-popup"
            >
              Okay
            </PrimaryButton>
          </div>
        </ShouldRender>
      )}

      <ShouldRender condition={!!error}>
        <div className={styles.error}>
          <Text
            error
            intl={error}
          />

          <Button
            name="submit-order"
            intl="tryAgain"
            onClick={() => onSubmitOrder(orderRef, maintenanceOrder)}
          />
        </div>
      </ShouldRender>
    </div>
  );
};
