import React from 'react';
import { IconNumText } from 'components/brikks/sub-components/IconNumText';
import { FormattedMessage } from 'react-intl';
import { InsuranceHouseUnderlineIcon } from 'assets/icons/brikks/InsuranceHouseUnderlineIcon';

export const ThingsAndResourcesPreview = () => (
  <IconNumText
    uppercase
    icon={<InsuranceHouseUnderlineIcon />}
    text={<FormattedMessage id="itemsCheckedOut" />}
    number="05"
  />
);
