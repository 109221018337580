import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'global/themes';
import styles from './styles.module.scss';

export const Text = ({
  children,
  element = 'p',
  size,
  intl,
  intlValues,
  primary,
  secondary,
  thin,
  uppercase,
  small,
  breakAll,
  large,
  ellipsis,
  dark,
  center,
  right,
  bold,
  gray,
  lightGray,
  white,
  brown,
  xl,
  xs,
  xxl1,
  xxl2,
  xxl3,
  xxl4,
  xxl5,
  clickable,
  fontStyle,
  fontWeight,
  medium,
  mediumxs,
  underline,
  error,
  className,
  italic,
  red,
  textDecoration,
  onClick = () => { },
}) => {
  const { theme } = useTheme();

  const textClassNames = classNames(
    styles.text,
    dark && styles.dark,
    primary && styles.primary,
    xs && styles.xs,
    center && styles.center,
    right && styles.right,
    bold && styles.bold,
    secondary && styles.secondary,
    lightGray && styles.lightGray,
    ellipsis && styles.ellipsis,
    small && styles.small,
    large && styles.large,
    white && styles.white,
    brown && styles.brown,
    xl && styles.xl,
    xxl1 && styles.xxl1,
    xxl2 && styles.xxl2,
    xxl3 && styles.xxl3,
    xxl4 && styles.xxl4,
    xxl5 && styles.xxl5,
    breakAll && styles.breakAll,
    medium && styles.medium,
    mediumxs && styles.mediumxs,
    thin && styles.thin,
    clickable && styles.clickable,
    uppercase && styles.uppercase,
    underline && styles.underline,
    error && styles.error,
    className,
    gray && theme.grayText,
    italic && styles.italic,
    red && styles.red,
    className,
  );

  const textStyles = { fontSize: size, fontWeight, fontStyle, textDecoration };

  return (
    intl ? (
      <FormattedMessage
        id={intl}
        values={intlValues}
      >
        {message => (
          React.createElement(element, { className: textClassNames, onClick, style: textStyles }, message)
        )}
      </FormattedMessage>
    ) : React.createElement(element, { className: textClassNames, onClick, style: textStyles }, children)
  );
};
