import React from 'react';
import { AppContext } from 'AppContext';
import classNames from 'classnames';
import { Text } from 'components/typography/Text';
import { Image } from 'components/media/Image';
import { FocusableElement } from 'components/FocusableElement';
import { tenantConfig } from 'global/tenant-config';
import {
  DARK_BG,
  TREES_BG,
  DARK_THEME_BG,
  BRF1_BG,
  BRF2_BG,
  BRF3_BG,
  BRF4_BG,
  BRF5_BG,
  BRF6_BG,
  BRF7_BG,
  BRF8_BG,
  BRF9_BG,
  BRF10_BG,
  BRF11_BG,
  BRF12_BG,
  BRF13_BG,
  BRF14_BG,
  BRF15_BG,
  BRF16_BG,
  BRF17_BG,
  BRF18_BG,
}
  from 'services/user/settings/utils';
import { BRFSOLO_THEME, BRF_THEME } from 'utils/theme';
import styles from './styles.module.scss';

export function BackgroundSettings() {
  const { userSettings: { background, updateUserSettings }, selectedTheme } = React.useContext(AppContext);

  return (
    <div className={styles.backgroundSettings}>
      <div className={styles.headingWrapper}>
        <Text
          element="h3"
          thin
          medium
          uppercase
          white
          intl="backgroundScreen"
          className={styles.heading}
        />
      </div>

      {[BRF_THEME, BRFSOLO_THEME].includes(selectedTheme)
        ? (
          <div className={styles.settings}>
            <FocusableElement
              className={classNames(styles.bgImage, background === BRF1_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF1_BG })}
            >
              <Image
                src={BRF1_BG}
                alt="Brf1"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF2_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF2_BG })}
            >
              <Image
                src={BRF2_BG}
                alt="Brf2"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF3_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF3_BG })}
            >
              <Image
                src={BRF3_BG}
                alt="Brf3"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF4_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF4_BG })}
            >
              <Image
                src={BRF4_BG}
                alt="Brf4"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF5_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF5_BG })}
            >
              <Image
                src={BRF5_BG}
                alt="Brf5"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF6_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF6_BG })}
            >
              <Image
                src={BRF6_BG}
                alt="Brf6"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF7_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF7_BG })}
            >
              <Image
                src={BRF7_BG}
                alt="Brf7"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF8_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF8_BG })}
            >
              <Image
                src={BRF8_BG}
                alt="Brf8"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF9_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF9_BG })}
            >
              <Image
                src={BRF9_BG}
                alt="Brf9"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF10_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF10_BG })}
            >
              <Image
                src={BRF10_BG}
                alt="Brf10"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF11_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF11_BG })}
            >
              <Image
                src={BRF11_BG}
                alt="Brf11"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF12_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF12_BG })}
            >
              <Image
                src={BRF12_BG}
                alt="Brf12"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF13_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF13_BG })}
            >
              <Image
                src={BRF13_BG}
                alt="Brf13"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF14_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF14_BG })}
            >
              <Image
                src={BRF14_BG}
                alt="Brf14"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF15_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF15_BG })}
            >
              <Image
                src={BRF15_BG}
                alt="Brf15"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF16_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF16_BG })}
            >
              <Image
                src={BRF16_BG}
                alt="Brf16"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF17_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF17_BG })}
            >
              <Image
                src={BRF17_BG}
                alt="Brf17"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === BRF18_BG && styles.active)}
              onClick={() => updateUserSettings({ background: BRF18_BG })}
            >
              <Image
                src={BRF18_BG}
                alt="Brf18"
              />
            </FocusableElement>
          </div>
        )
        : (
          <div className={styles.settings}>
            <FocusableElement
              className={classNames(styles.bgImage, background === tenantConfig?.initialBackground && styles.active)}
              onClick={() => updateUserSettings({ background: tenantConfig?.initialBackground })}
            >
              <Image
                src={tenantConfig?.initialBackground}
                alt="White background image"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === DARK_BG && styles.active)}
              onClick={() => updateUserSettings({ background: DARK_BG })}
            >
              <Image
                src={DARK_BG}
                alt="Dark background image"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === TREES_BG && styles.active)}
              onClick={() => updateUserSettings({ background: TREES_BG })}
            >
              <Image
                src={TREES_BG}
                alt="Trees background image"
              />
            </FocusableElement>

            <FocusableElement
              className={classNames(styles.bgImage, background === DARK_THEME_BG && styles.active)}
              onClick={() => updateUserSettings({ background: DARK_THEME_BG })}
            >
              <Image
                src={DARK_THEME_BG}
                alt="Dark abstract image"
              />
            </FocusableElement>
          </div>
        )}
    </div>
  );
}
