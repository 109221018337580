import { SubmitMaintenanceOrderActions } from './actions';

const initialState = {
  isSuccessful: false,
  loading: false,
  error: '',
};

export function submitMaintenanceOrderReducer(state = initialState, action) {
  switch (action.type) {
    case SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER_SUCCESS:
      return {
        ...state,
        isSuccessful: true,
        loading: false,
      };
    case SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER_RESET:
      return initialState;
    default:
      return state;
  }
}
