import React from 'react';
import { FocusableElement } from 'components/FocusableElement';
import { Icon } from 'semantic-ui-react';
import { useOrderModes } from 'services/order/order-mode/hook';
import styles from './styles.module.scss';

export const InfoButton = ({ onClick }) => {

  const { orderMode } = useOrderModes();

  return (
    orderMode && (
      <div className={styles.infoIcon}>
        <FocusableElement
          onClick={onClick}
        >
          <Icon
            name="info circle"
            color="black"
            size="large"
          />
        </FocusableElement>
      </div>
    )

  );
};
