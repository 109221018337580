import React, { useContext, useState } from 'react';
import { Text } from 'components/typography/Text';
import { daysFormat, formatDate } from 'utils/timeAndDate';
import { DatePicker } from 'components/form-elements/pickers/DatePicker';
import { TerminateAgreementModalContext } from 'components/brikks/home/TerminateAgreement/components/TerminateModal/index';
import { Heading } from 'components/typography/Heading';
import { BankIDMultipleSigning } from 'components/bank-id/BankIDMultipleSigning';
import { Terminate } from
  'components/brikks/home/TerminateAgreement/components/TerminateModal/components/SignAgreement/Terminate';
import { addMonths, differenceInMonths, lastDayOfMonth } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserDetails } from 'services/user/details/hook';
import { APARTMENT_TYPES } from 'utils/agreement';
import styles from './styles.module.scss';

const initialTerminationDetails = {
  reason: '',
  address: '',
  postcode: '',
  city: '',
};

const getFinalTerminationDate = (terminationDate, selectedAgreement) => {
  switch (selectedAgreement.productType) {
    case 'Parkeringsplats': {
      const monthsDifference = differenceInMonths(terminationDate, new Date(selectedAgreement.agreementFrom));
      if (monthsDifference % 3 === 2) {
        return lastDayOfMonth(addMonths(new Date(selectedAgreement.agreementFrom), monthsDifference + selectedAgreement.uppsTid));
      }
      return lastDayOfMonth(addMonths(new Date(selectedAgreement.agreementFrom), monthsDifference + (2 - (monthsDifference % 3))));
    }
    case 'Lägenhet':
      return lastDayOfMonth(addMonths(terminationDate, selectedAgreement.uppsTid));
    default:
      return lastDayOfMonth(addMonths(terminationDate, selectedAgreement.uppsTid));
  }
};

export const SignAgreement = () => {
  const { selectedAgreements } = useContext(TerminateAgreementModalContext);

  const initialDate = new Date();
  const [terminationDate, setTerminationDate] = useState(initialDate);
  const [terminationDetails, setTerminationDetails] = useState(initialTerminationDetails);

  const intl = useIntl();
  const { userDetails } = useUserDetails();

  return (
    <>
      <div className={styles.information}>
        <Text size={14}>
          <FormattedMessage
            id="firstPossibleNotice"
            values={{
              date: formatDate(terminationDate, daysFormat),
            }}
          />
        </Text>
        <Text
          size={18}
          bold
        >
          {formatDate(getFinalTerminationDate(terminationDate, selectedAgreements), daysFormat)}
        </Text>
      </div>

      <div className={styles.signParagraph}>
        <Text
          intl="whenToTerminateAgreement"
          size={14}
          className={styles.label}
        />

        <DatePicker
          selectedDate={terminationDate}
          minDate={initialDate}
          dateFormat={daysFormat}
          onChange={(selectedDate) => (
            setTerminationDate(!selectedDate ? initialDate : selectedDate)
          )}
        />
      </div>

      <div className={styles.signParagraph}>
        <Text
          intl="bySigningWillGiveNoticeToTerminate"
          size={14}
        />

        <Text
          size={23}
          bold
        >
          <span className={styles.blue}>{selectedAgreements?.productType}</span>{' '}
          <span><FormattedMessage id="ends" /></span>{' '}
          <span className={styles.blue}>
            {formatDate(getFinalTerminationDate(terminationDate, selectedAgreements), daysFormat)}
          </span>
        </Text>
      </div>

      <Heading>
        <Text
          intl="chooseEidAndSign"
          size={22}
          uppercase
        />
      </Heading>

      <div className={styles.signParagraph}>
        <BankIDMultipleSigning
          showTerminationDetails={APARTMENT_TYPES.includes(selectedAgreements?.productType)}
          secondPersonData={selectedAgreements?.secondPersonData}
          secondPersonSignedTermination={selectedAgreements?.secondPersonSignedTermination}
          userVisibleData={
            intl.formatMessage(
              {
                id: 'terminateBankIDSignUserData',
              },
              {
                firstName: userDetails?.firstName,
                lastName: userDetails?.lastName,
                type: selectedAgreements?.productType,
                terminationDate: formatDate(getFinalTerminationDate(terminationDate, selectedAgreements), daysFormat),
              },
            )
          }
          setTerminationDetails={setTerminationDetails}
          finalTerminationDate={getFinalTerminationDate(terminationDate, selectedAgreements)}
        >
          <Terminate
            selectedDate={terminationDate}
            endDate={getFinalTerminationDate(terminationDate, selectedAgreements)}
            agreementNumber={selectedAgreements?.agreementNumber}
            productType={selectedAgreements?.productType}
            terminationDetails={terminationDetails}
          />
        </BankIDMultipleSigning>
      </div>
    </>
  );
};
