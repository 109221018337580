export const UserSettingsActions = {
  GET_USER_SETTINGS: '@userSettings/get',
  GET_USER_SETTINGS_SUCCESS: '@userSettings/get/success',

  UPDATE_USER_SETTINGS: '@userSettings/update',
};

export function getUserSettings() {
  return {
    type: UserSettingsActions.GET_USER_SETTINGS,
  };
}

export function getUserSettingsSuccess(settings) {
  return {
    type: UserSettingsActions.GET_USER_SETTINGS_SUCCESS,
    payload: settings,
  };
}

export function updateUserSettings(settings) {
  return {
    type: UserSettingsActions.UPDATE_USER_SETTINGS,
    payload: settings,
  };
}

