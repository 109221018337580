import React from 'react';
import { useIntl } from 'react-intl';
import { IFrame } from 'components/media/IFrame';

export const Maintenance = () => {
  const intl = useIntl();

  return (
    <>
      <IFrame
        width={240}
        height={170}
        src={intl.formatMessage({ id: 'maintenanceWaterTapVideo' })}
      />

      <IFrame
        width={240}
        height={170}
        src={intl.formatMessage({ id: 'maintenanceFloorDrainVideo' })}
      />
    </>
  );
};
