import { useRef } from 'react';

export function useClickOutside(onClick) {
  const ref = useRef(null);

  const onClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick();
    }
  };

  return {
    ref,
    onClickOutside,
  };
}
