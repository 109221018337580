import React from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

export const SeeMoreButton = ({ error, loading, onClick, onError, disabled, brikkNameIntl }) => (
  <Button
    className={styles.button}
    disabled={loading || error || disabled}
    onClick={error ? onError : onClick}
  >
    <FormattedMessage id="goTo" /> <b><FormattedMessage id={brikkNameIntl} /></b>
  </Button>
);
