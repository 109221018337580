export const MaintenanceProductsActions = {
  MAINTENANCE_PRODUCTS_GET: '@maintenanceProducts/get',
  MAINTENANCE_PRODUCTS_GET_SUCCESS: '@maintenanceProducts/get/success',
  MAINTENANCE_PRODUCTS_GET_FAIL: '@maintenanceProducts/get/fail',
};

export function getMaintenanceProducts({ type, unit, measure }) {
  return {
    type: MaintenanceProductsActions.MAINTENANCE_PRODUCTS_GET,
    payload: { type, unit, measure },
  };
}

export function getMaintenanceProductsSuccess({ type, unit, measure, products }) {
  return {
    type: MaintenanceProductsActions.MAINTENANCE_PRODUCTS_GET_SUCCESS,
    payload: { type, unit, measure, products },
  };
}

export function getMaintenanceProductsFail({ type, unit, measure, error }) {
  return {
    type: MaintenanceProductsActions.MAINTENANCE_PRODUCTS_GET_FAIL,
    payload: { type, unit, measure, error },
  };
}
