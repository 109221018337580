export function mapOrderToAPIFormat(maintenanceOrder) {
  const orderKeys = Object.keys(maintenanceOrder);
  return orderKeys.map(key => {
    const order = maintenanceOrder[key];

    return {
      typename: order.selectedSurface.type,
      unitname: order.selectedSurface.unit,
      measure: order.selectedSurface.measure,
      name: order.selectedProduct.name,
      code: order.selectedProduct.code,
    };
  });
}
