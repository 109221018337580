import sassVariables from 'global/_styles.scss';
import React from 'react';

export const useWaterIconFill = variant => {
  const gradient = (
    <linearGradient
      id="combined-water-gradient"
      x2="1"
      y2="0"
    >
      <stop
        offset="0%"
        stopColor={sassVariables.red}
      />
      <stop
        offset="49%"
        stopColor={sassVariables.red}
      />
      <stop
        offset="51%"
        stopColor={sassVariables.blue}
      />
      <stop
        offset="100%"
        stopColor={sassVariables.blue}
      />
    </linearGradient>
  );

  if (variant === 'hotWater') return [sassVariables.red, gradient];
  if (variant === 'coldWater') return [sassVariables.blue, gradient];
  if (variant === 'total') return ['url(#combined-water-gradient)', gradient];

  return [variant, gradient];
};
