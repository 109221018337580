import React, { createContext } from 'react';
import { Column } from 'components/layout/Grid/components/Column';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const GridContext = createContext({ brikkLayout: false });

export const Grid = ({
  children,
  style,
  transparent,
  noPadding,
  paddingTop,
  brikkLayout,
  oneColumn,
  twoColumns,
  twoEqualColumns,
  className,
}) => (
  <GridContext.Provider value={{ brikkLayout, twoColumns, transparent }}>
    <div
      className={classNames(
        styles.grid,
        brikkLayout && styles.noTopPadding,
        oneColumn && styles.oneColumn,
        twoColumns && styles.twoColumns,
        twoEqualColumns && styles.twoEqualColumns,
        transparent && styles.transparent,
        paddingTop && styles.paddingTop,
        noPadding && styles.noPadding,
        className,
      )}
      style={style}
    >
      {children}
    </div>
  </GridContext.Provider>
);

Grid.Column = Column;
