import { useDispatch, useSelector } from 'react-redux';
import { setMultiGrid } from './actions';

export function useMultiGrid() {
  const { selectedGrid } = useSelector(state => state.multiGrid);

  const dispatch = useDispatch();

  return {
    selectedGrid,
    setMultiGrid: (grid) => dispatch(setMultiGrid(grid)),
  };
}
