import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Radio } from 'components/form-elements/radios/Radio';
import { Text } from 'components/typography/Text';

export function LinkRadio({ intl, link, onChange, toggle, checked }) {

  return (
    <div style={{
      display: 'flex',
      gap: 5,
      justifyContent: 'center',
    }}
    >
      <Radio
        onChange={onChange}
        toggle={toggle}
        checked={checked}
      />

      <FormattedMessage
        id={intl}
        values={{
          a: (...chunks) => (
            <a
              style={{ fontSize: 13 }}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
          text: (...chunks) => (
            <Text>
              {chunks}
            </Text>
          ),
        }}
      />
    </div>
  );
}
