export const SubmitMaintenanceOrderActions = {
  SUBMIT_MAINTENANCE_ORDER: '@submitMaintenanceOrder',
  SUBMIT_MAINTENANCE_ORDER_SUCCESS: '@submitMaintenanceOrder/success',
  SUBMIT_MAINTENANCE_ORDER_FAIL: '@submitMaintenanceOrder/fail',

  SUBMIT_MAINTENANCE_ORDER_RESET: '@submitMaintenanceOrder/reset',
};

export function submitMaintenanceOrder(orderRef, maintenanceOrder) {
  return {
    type: SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER,
    payload: { orderRef, maintenanceOrder },
  };
}

export function submitMaintenanceOrderSuccess() {
  return {
    type: SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER_SUCCESS,
  };
}

export function submitMaintenanceOrderFail(error) {
  return {
    type: SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER_FAIL,
    payload: error,
  };
}

export function submitMaintenanceOrderReset() {
  return {
    type: SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER_RESET,
  };
}
