import { useDispatch, useSelector } from 'react-redux';
import { resetPass } from './actions';

export function useResetPass() {
  const { isResetSuccessful, loading, error } = useSelector(state => state.resetPass);

  const dispatch = useDispatch();

  return {
    onResetPass: (email, password, code) => dispatch(resetPass(email, password, code)),
    isResetSuccessful,
    loading,
    error,
  };
}
