import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { AddScenario, ManageScenarios } from 
  'components/brikks/smartness/Lights/components/LightningScenarios/components';

export const LightningScenarios = () => (
  <MultiTabMenu
    title="controls"
    defaultTab="createScenario"
    tabs={{
      createScenario: {
        title: 'createScenario',
        component: AddScenario,
        menus: [] },
      manageScenario: {
        title: 'manageScenario',
        component: ManageScenarios,
        menus: [],
      },
    }}
  />
);

