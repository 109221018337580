import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import styles from 'components/brikks/home/Invoices/components/Modal/components/InvoicesTable/styles.module.scss';
import { Invoice } from 'components/brikks/home/Invoices/components/Modal/components/InvoicesTable/components/Invoice';

export const InvoicesTable = ({ invoices }) => (
  <Table
    singleLine
    striped
    unstackable
  >
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <FormattedMessage id="monthAndStatus" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="dueDate" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="paid" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="remainingBalance" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="totalBalance" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="ocr" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="download" />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {invoices.map((item, index) => {
        const isInvoicePaid = item.status.toLowerCase() === 'paid';

        return (
          <Table.Row key={index}>
            <Table.Cell className={styles.statusCell}>
              {`${item.period.toLowerCase()}: `}
              <FormattedMessage id={item.status.toLowerCase()} />
            </Table.Cell>
            <Table.Cell>{item.dueDate || '-'}</Table.Cell>
            <Table.Cell>{!isInvoicePaid ? '-' : (item.latestPaymentDate || '-')}</Table.Cell>
            <Table.Cell>{item.remainingBalance}</Table.Cell>
            <Table.Cell>{item.totalBalance ? (<>{item.totalBalance}<FormattedMessage id="perMonth" /></>) : ''}</Table.Cell>
            <Table.Cell>
              {item.ocrRef || '-'}
            </Table.Cell>
            <Table.Cell className={styles.downloadCell}>
              <Invoice
                invoiceNumber={item.invoiceNumber}
                invoiceType={item.type}
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  </Table>
);
