import React from 'react';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { Tooltip as GeneralTooltip } from 'components/Tooltip';
import styles from './styles.module.scss';

export const Tooltip = ({ children }) => (
  <div className={styles.tooltip}>
    <GeneralTooltip element={(
      <InfoIcon
        width={16}
        gray
      />
)}
    >
      {children}
    </GeneralTooltip>
  </div>
);
