import { authorize, getRequest, postRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';

const url = `${AWS_API_URL}/avatar`;

export async function getUserAvatarAPI() {
  return getRequest(url, await authorize());
}

export async function uploadUserAvatarAPI(avatar) {
  return postRequest(
    url,
    avatar,
    await authorize({
      headers: { 'content-type': 'multipart/form-data' },
    }),
  );
}
