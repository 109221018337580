import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'global/themes';
import styles from './styles.module.scss';

export const Heading = ({
  children, 
  dark,
  intl,
  gray,
  xxl1,
  xxl,
  xxl3,
  className,
  normal,
  red,
}) => {
  const { theme } = useTheme();

  return (
    <div
      className={classNames(
        theme.textColor,
        styles.heading,
        xxl && styles.xxl,
        gray && styles.gray,
        xxl1 && styles.xxl1,
        xxl3 && styles.xxl3,
        normal && styles.normal,
        dark && styles.dark,
        red && styles.red,
        className,
      )}
    >
      {intl ? (
        <FormattedMessage
          id={intl}
          defaultMessage={intl}
        />
      ) : children}
    </div>
  );
};
