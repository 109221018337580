import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import work from 'assets/media/work.jpg';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { WorkCogSearchIcon } from 'assets/icons/brikks/WorkCogSearchIcon';
import { LinkBrikkContent } from 'components/brikks/sub-components/LinkBrikkContent';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const Work = ({ position, status, code, name }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<WorkCogSearchIcon />}
            heading="workBrikk"
            brikkKey={code}
            name={name}
            status={status}

          />

          <Brikk.Content>
            <CircularImage
              src={work}
              alt="Work Logo"
            />

            <LinkBrikkContent link="arbetsformedlingen.se" />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="Work Link"
              to="https://arbetsformedlingen.se/"
              brikkNameIntl="Work"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkInfoModal
        title="Work info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />
    </>
  );
};
