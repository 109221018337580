import { usePrevious } from 'utils/hooks/usePrevious';
import { useEffect } from 'react';

export function useScrollToTop(activeStep) {
  const prevActiveStep = usePrevious(activeStep);

  useEffect(() => {
    if (prevActiveStep !== undefined && prevActiveStep !== activeStep) {
      const popupModal = document.getElementById('popup-root');
      if (popupModal) {
        popupModal.firstChild.scrollTop = 0;
      }
    }
  }, [prevActiveStep, activeStep]);
}
