import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'global/themes';
import styles from './styles.module.scss';

const Calendar = (props) => {

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const { theme } = useTheme();

  return (
    <div className={classNames(styles.calendar, theme.boxShadow)}>
      <div className={styles.header}>
        {months[parseInt(props?.month, 10)]}
      </div>

      <div className={classNames(styles.body, theme.iconBackgroundSecondaryColor)}>
        <div className={classNames(styles.date, theme.textColor)}>
          {props?.date}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
