import React, { useContext } from 'react';
import classNames from 'classnames';
import { Menu } from 'semantic-ui-react';
import { useTheme } from 'global/themes';
import { AppContext } from 'AppContext';
import { ShouldRender } from 'components/ShouldRender';
import { Logo } from 'components/headers/AppHeader/components/Logo';
import { AuthenticatedMenu } from './componenets/AuthenticatedMenu';
import styles from './styles.module.scss';

const PageHeader = ({ transparent, fixed, noLogin, children = <AuthenticatedMenu /> }) => {
  const { isAuthenticated } = useContext(AppContext);

  const { theme } = useTheme();

  return (
    <header className={classNames(styles.header, fixed && styles.fixed)}>
      <Menu
        borderless
        className={classNames(styles.pageHeader, transparent && styles.transparent, theme.backgroundColor)}
      >
        <Logo />

        {!isAuthenticated ? (
          <ShouldRender condition={!noLogin}>
            <Menu.Menu
              position="right"
              className="user-menu"
            >
              <Menu.Item
                as="a"
                onClick={() => { window.location.href = '/login'; }}
                className="login"
              >
                Login
              </Menu.Item>
            </Menu.Menu>
          </ShouldRender>
        ) : children}

      </Menu>
    </header>
  );
};

export default PageHeader;
