import React, { createContext, useState } from 'react';
import { useAgreements } from 'services/agreements/agreements';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { Text } from 'components/typography/Text';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import terminateImgSrc from 'assets/media/terminate-agreement.png';
import { AgreementList } from 'components/brikks/home/TerminateAgreement/components/TerminateModal/components/AgreementsList';
import { SignAgreement } from 'components/brikks/home/TerminateAgreement/components/TerminateModal/components/SignAgreement';
import { useParams } from 'react-router-dom';
import { LinkModal } from 'components/modals/LinkModal';
import { MyDocumentsModal } from 'components/brikks/home/MyDocuments/components/MyDocumentsModal';
import { LinkModalButton } from 'components/form-elements/buttons/LinkModalButton';

export const TerminateAgreementModalContext = createContext();

export const TerminateAgreementModal = ({ selectedAgreement }) => {
  const { id } = useParams();
  const { agreements } = useAgreements();

  const defaultSelectedAgreement = agreements.find(a => {
    if (id) {
      return id === a.agreementNumber && a.status === 'pending' && a.secondPersonSignedTermination;
    }
    if (selectedAgreement) {
      return a.agreementNumber === selectedAgreement;
    }
    return a.status === 'active' || (a.status === 'pending' && a.secondPersonSignedTermination);
  });

  const [selectedAgreements, setSelectedAgreements] = useState(defaultSelectedAgreement);

  return (
    <TerminateAgreementModalContext.Provider
      value={{ selectedAgreements, setSelectedAgreements }}
    >
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="terminateAgreements" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="terminateAgreements"
          >
            <ModalIntro>
              <ModalIntro.Image
                src={terminateImgSrc}
                alt="Grass and woods"
              />

              <ModalIntro.Text intl="terminateAgreementDescription" />

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LinkModal
                  intl="moreInfo"
                  buttonTriggerModal={LinkModalButton}
                  brikkModal={MyDocumentsModal}
                  modalHeading="myDocuments"
                />
              </div>

            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="chooseAgreement">
            <AgreementList agreements={agreements} />
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="chooseDate">
            {selectedAgreements
              ? <SignAgreement />
              : (
                <Text
                  intl="noAgreementFound"
                  lightGray
                  size={16}
                />
              )}
          </Brikk.Heading>
        </Grid.Column>
      </Grid>
    </TerminateAgreementModalContext.Provider>
  );
};
