/**
 * User's avatar
 */
import { UserAvatarActions } from './actions';

const initialState = {
  avatar: '',
  loading: false,
  error: null,
  calledOnce: false,
};

export function userAvatarReducer(state = initialState, action) {
  switch (action.type) {
    case UserAvatarActions.GET_AVATAR:
    case UserAvatarActions.UPLOAD_AVATAR:
      return {
        ...state,
        loading: true,
        error: null,
        calledOnce: true,
      };

    case UserAvatarActions.GET_AVATAR_SUCCESS:
    case UserAvatarActions.UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        avatar: action.payload,
        loading: false,
      };

    case UserAvatarActions.GET_AVATAR_FAIL:
    case UserAvatarActions.UPLOAD_AVATAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
