import { SubmitCreateOrderActions } from './actions';

const initialState = {
  isSubmitted: false,
  loading: false,
  error: '',
};

export function submitCreateOrderReducer(state = initialState, action) {
  switch (action.type) {
    case SubmitCreateOrderActions.SUBMIT_CREATE_ORDER:
      return {
        ...state,
        isSubmitted: false,
        loading: true,
        error: '',
      };
    case SubmitCreateOrderActions.SUBMIT_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        loading: false,
      };
    case SubmitCreateOrderActions.SUBMIT_CREATE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SubmitCreateOrderActions.SUBMIT_CREATE_ORDER_RESET:
      return initialState;
    default:
      return state;
  }
}
