import React, { useContext } from 'react';
import { Menu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ArrangeIcon } from 'assets/icons/ArrangeIcon';
import classNames from 'classnames';
import { useTheme } from 'global/themes';
import { AppContext } from '../../../../../AppContext';
import { UserProfile } from '../../../UserProfile';
import { FocusableElement } from '../../../../FocusableElement';
import { Settings } from './components/Settings';
import styles from './styles.module.scss';

export const AuthenticatedMenu = () => {
  const { shouldReorderBrikks, setShouldReorderBrikks } = useContext(AppContext);

  const { theme } = useTheme();

  return (
    <>
      <Menu.Menu
        position="right"
        className={styles.menu}
      >
        <UserProfile />

        <FocusableElement onClick={() => setShouldReorderBrikks(!shouldReorderBrikks)}>
          <Menu.Item
            as="a"
            className={classNames(styles.arrangeBrikks, styles.link)}
          >
            <span className={classNames(styles.menuText, theme.textColor)}>
              {shouldReorderBrikks
                ? <FormattedMessage id="lockBrikks" /> : <FormattedMessage id="arrangeBrikks" />}
            </span>
            <ArrangeIcon isActive={shouldReorderBrikks} />
          </Menu.Item>
        </FocusableElement>

        <Menu.Item
          as="a"
          className={styles.link}
        >
          <Settings />
        </Menu.Item>
      </Menu.Menu>
    </>
  );
};
