export const ConfirmEmailActions = {
  CONFIRM_EMAIL: '@confirmEmail/',
  CONFIRM_EMAIL_SUCCESS: '@confirmEmail/success',
  CONFIRM_EMAIL_FAIL: '@confirmEmail/fail',

  CONFIRM_EMAIL_RESET: '@confirmEmail/reset',
};

export function confirmEmail(email) {
  return {
    type: ConfirmEmailActions.CONFIRM_EMAIL,
    payload: email,
  };
}

export function confirmEmailSuccess() {
  return {
    type: ConfirmEmailActions.CONFIRM_EMAIL_SUCCESS,
  };
}

export function confirmEmailFail(error) {
  return {
    type: ConfirmEmailActions.CONFIRM_EMAIL_FAIL,
    payload: error,
  };
}

export function confirmEmailReset() {
  return {
    type: ConfirmEmailActions.CONFIRM_EMAIL_RESET,
  };
}
