import { useDispatch, useSelector } from 'react-redux';
import { downloadAgreement } from './actions';

export function useDownloadAgreement(agreementNumber) {
  const { loading, error } = useSelector(state => state.downloadAgreement);

  const dispatch = useDispatch();

  return {
    onDownloadAgreement: () => dispatch(downloadAgreement(agreementNumber)),
    loading: loading[agreementNumber],
    error: error[agreementNumber],
  };
}
