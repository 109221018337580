import React, { useContext, useMemo } from 'react';
import { TrendArrowIcon } from 'assets/icons/TrendArrowIcon';
import { InfoCard } from 'components/cards/InfoCard';
import { WaterUsageContext } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/index';

export const TrendCard = ({ value, loading, variant }) => {
  const { error24Hours, error30Days } = useContext(WaterUsageContext);

  const { trendValue, direction } = useMemo(() => {
    let trendValue;
    let direction;

    const percentageValue = `${Math.abs(value)}%`;

    if (Number.isNaN(value)) {
      trendValue = 'N/A';
    } else if (value < 0) {
      trendValue = '-'.concat(percentageValue);
      direction = 'down';
    } else if (value === 0) {
      trendValue = '+/-'.concat(percentageValue);
      direction = 'middle';
    } else {
      trendValue = '+'.concat(percentageValue);
      direction = 'up';
    }

    return { trendValue, direction };
  }, [value]);

  return (
    <div
      data-tip
      data-for="waterTrendTooltip"
    >
      <InfoCard
        icon={(
          <TrendArrowIcon
            direction={loading ? 'middle' : direction}
            variant={variant}
          />
        )}
        value={trendValue}
        title="trend"
        loading={loading}
        error={error24Hours || error30Days}
      />
    </div>
  );
};
