import React from 'react';
import { Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function FormErrors({ formerrors, apierrors }) {
  if (formerrors && formerrors.blankfieldError) {
    return (
      <Message negative>
        <Message.Content>
          <FormattedMessage id="fieldsRequired" />
        </Message.Content>
      </Message>
    );
  }

  if (apierrors) {
    return (
      <Message negative>
        <Message.Content>{apierrors}</Message.Content>
      </Message>
    );
  }

  if (formerrors && formerrors.cognitoError) {
    if (formerrors.cognitoError.name === 'NotAuthorizedException') {
      return (
        <Message negative>
          <Message.Content><FormattedMessage id="invalidCurrentPassword" /></Message.Content>
        </Message>
      );
    }
    if (formerrors.cognitoError.name === 'UserNotFoundException') {
      return (
        <Message negative>
          <Message.Content><FormattedMessage id="emailNotRegistered" /></Message.Content>
        </Message>
      );
    }
    return (
      <Message negative>
        <Message.Content>{formerrors.cognitoError.message}</Message.Content>
      </Message>
    );
  }

  return <div />;
}

export default FormErrors;
