import { useSelector } from 'react-redux';

export function useInitPassReset() {
  const { isInitSuccessful, loading, error } = useSelector(state => state.initPassReset);

  return {
    isInitSuccessful,
    loading,
    error,
  };
}
