import { takeLatest, call, put } from 'redux-saga/effects';
import { initPassReset } from 'services/auth/init-pass-reset/actions';
import { ConfirmEmailActions, confirmEmailFail, confirmEmailSuccess } from './actions';
import { confirmEmailAPI } from './api';

function* confirmEmailEffect({ payload }) {
  try {
    yield call(confirmEmailAPI, payload);
  } catch (e) {
    switch (e.code) {
      case 'UserNotFoundException':
        yield put(confirmEmailFail('emailNotFound'));
        break;
      case 'NotAuthorizedException':
        yield put(confirmEmailSuccess());
        break;
      case 'PasswordResetRequiredException':
        yield put(initPassReset(payload));
        break;
      default:
        yield put(confirmEmailFail('genericErrorMessage'));
        break;
    }
  }
}

export const confirmEmailEffects = [
  takeLatest(ConfirmEmailActions.CONFIRM_EMAIL, confirmEmailEffect),
];
