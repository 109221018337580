import { takeLatest, call, put, delay } from 'redux-saga/effects';
import { confirmEmailFail } from '../confirm-email/actions';
import { InitPassResetActions, initPassResetFail, initPassResetReset, initPassResetSuccess } from './actions';
import { initPassResetAPI } from './api';

function* initPassResetEffect({ payload }) {
  try {
    yield call(initPassResetAPI, payload);
    yield put(initPassResetSuccess());

    yield delay(100);
    yield put(initPassResetReset());
  } catch (e) {
    yield put(confirmEmailFail('genericErrorMessage'));
    yield put(initPassResetFail('genericErrorMessage'));
  }
}

export const initPassResetEffects = [
  takeLatest(InitPassResetActions.INIT_PASS_RESET, initPassResetEffect),
];
