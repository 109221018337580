import React from 'react';
import { Card } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const CustomCard = ({ children, isDragging }) => (
  <div className={classNames(styles.brikk, isDragging && styles.dragged)}>
    <Card
      raised
      className={styles.card}
    >
      {children}
    </Card>
  </div>
);
