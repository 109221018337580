import React from 'react';
import { useDownloadAgreement } from 'services/agreements/download-agreement/hook';
import { DownloadIcon } from 'assets/icons/DownloadIcon';

export const Agreement = ({ agreementNumber }) => {
  const { loading, error, onDownloadAgreement } = useDownloadAgreement(agreementNumber);

  return (
    <DownloadIcon
      loading={loading}
      error={error}
      onClick={onDownloadAgreement}
    />
  );
};
