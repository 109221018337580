import React from 'react';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { TotalCost } from 'components/item/TotalCost';
import styles from './styles.module.scss';

export const ProductCost = ({ onAddProduct, selectedPurchases }) => (
  <>
    <div className={styles.productActions}>
      <a href="#maintenanceCost">
        <div className={styles.costContainer}>
          <div className={styles.addItem}>
            <ProceedButton onClick={onAddProduct}>
              <ProceedButton.Button intl="addThis" />
              <ProceedButton.CartIcon />
            </ProceedButton>
          </div>
        </div>
      </a>

      <TotalCost totalCost={Object.values(selectedPurchases).reduce(
        (prevSum, { selectedSurface }) => prevSum + selectedSurface.residualValue, 0,
      )}
      />
    </div>
  </>
);
