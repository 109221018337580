import React, { createContext, useContext, useMemo } from 'react';
import { WaterUsageContext } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/index';
import { calculateTrend } from 'utils/math';

export const TrendContext = createContext({
  loading: false,
  totalTrend: 0,
  coldTrend: 0,
  hotTrend: 0,
});

export const TrendProvider = ({ children }) => {
  const {
    loading24Hours,
    waterUsage24Hours,
    loading30Days,
    waterUsage30Days,
  } = useContext(WaterUsageContext);

  const average30Days = useMemo(() => {
    if (waterUsage30Days) {
      const { coldWater, hotWater, totalWater } = waterUsage30Days;

      const totalColdAverage30Days = (coldWater.total / coldWater.values.length);
      const totalHotAverage30Days = (hotWater.total / hotWater.values.length);
      const totalAverage30Days = (totalWater / coldWater.values.length);

      return { totalAverage30Days, totalColdAverage30Days, totalHotAverage30Days };
    }
    return { totalAverage30Days: 0, totalColdAverage30Days: 0, totalHotAverage30Days: 0 };

  }, [waterUsage30Days]);

  const { totalTrend, coldTrend, hotTrend } = useMemo(() => {
    if (waterUsage24Hours && average30Days) {
      const { coldWater, hotWater, totalWater } = waterUsage24Hours;
      const { totalAverage30Days, totalColdAverage30Days, totalHotAverage30Days } = average30Days;

      const coldTrend = calculateTrend(totalColdAverage30Days, coldWater.total);
      const hotTrend = calculateTrend(totalHotAverage30Days, hotWater.total);
      const totalTrend = calculateTrend(totalAverage30Days, totalWater);

      return { totalTrend, coldTrend, hotTrend };
    }

    return { totalTrend: 0, coldTrend: 0, hotTrend: 0 };
  }, [waterUsage24Hours, average30Days]);

  return (
    <TrendContext.Provider value={{
      loading: loading30Days || loading24Hours,
      totalTrend,
      coldTrend,
      hotTrend,
    }}
    >
      {children}
    </TrendContext.Provider>
  );
};
