import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { ServiceTicketsList } from 'components/brikks/home/ServiceStatus/components/ServiceStatusModal/components/ServiceTicketsList';

export const ServiceTickets = () => (
  <MultiTabMenu
    title="serviceTickets"
    defaultTab="open"
    tabs={{
      open: {
        title: 'open',
        component: ServiceTicketsList,
        menus: [] },
      closed: {
        title: 'closed',
        component: ServiceTicketsList,
        menus: [],
      },
    }}
  />
);
