import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const EmailsInput = ({ setEmailRecipients, emailRecipients }) => {

  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const isInList = (email) => emailRecipients.includes(email);
  const isEmail = (email) => /[\w\d-]+@[\w\d-]+\.[\w\d-]+/.test(email);

  const handleChange = evt => {
    setValue(evt.target.value);
    setError(null);
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);
      return false;
    }
    return true;
  };

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      const vlu = value.trim();

      if (vlu && isValid(vlu)) {
        setEmailRecipients([...emailRecipients, value]);
        setValue('');
      }
    }
  };

  const handleDelete = (item) => {
    setEmailRecipients(emailRecipients.filter(i => i !== item));
  };

  return (
    <>
      <div className={styles.emailList}>
        {emailRecipients.map(item => (
          <div
            className={styles.tagItem}
            key={item}
          >
            {item}
            <button
              type="button"
              className={styles.button}
              onClick={() => handleDelete(item)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <input
        className={classNames(styles.input, error && styles.hasError)}
        value={value}
        placeholder="Type email and press Enter"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
      {error && <p className={styles.error}>{error}</p>}
    </>
  );
};
