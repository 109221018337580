import React from 'react';
import { Brikk } from 'components/Brikk';
import { useAgreements } from 'services/agreements/agreements';
import { AgreementItem } from './components/AgreementItem';

export const Agreements = () => {
  const { agreements } = useAgreements();
  return (
    <Brikk.Heading
      id="status"
      heading="status"
    >
      {
        agreements.map(agreement => (
          <AgreementItem
            key={agreement.agreementNumber}
            agreement={agreement}
          />
        ))
      }
    </Brikk.Heading>
  );
};
