import React, { useCallback, useEffect, useState } from 'react';
import styles from 'components/brikks/society/RSSFeed/components/RSSFeedModal/components/FeedArticles/styles.module.scss';
import { flatten } from 'lodash';
import { Heading } from 'components/typography';
import { Feed } from 'components/feed/Feed';
import { FormattedMessage } from 'react-intl';
import { FeedList } from 'components/brikks/society/RSSFeed/components/RSSFeedModal/components/FeedArticles/components/FeedList';

export const FeedArticles = ({ feed, onSelectArticle }) => {
  const [selectedFeed, setSelectedFeed] = useState(feed[0]);

  const selectAllArticles = useCallback(() => {
    const allArticlesFeed = { articles: [] };
    const flattenedArticles = flatten(feed.map(feed => feed.articles));

    allArticlesFeed.articles = flattenedArticles.sort((a, b) => b.datePublished - a.datePublished);

    setSelectedFeed(allArticlesFeed);
  }, [feed, setSelectedFeed]);

  useEffect(() => selectAllArticles(), [selectAllArticles]);

  return (
    <div className={styles.feedArticles}>
      <div style={{ visibility: 'hidden' }}>
        <Heading xxl>
          <FormattedMessage id="feedsAndArticles" />
        </Heading>
      </div>

      <div className={styles.selection}>
        <div className={styles.feeds}>
          <FeedList
            selectedFeed={selectedFeed}
            selectAllArticles={selectAllArticles}
            setSelectedFeed={setSelectedFeed}
            feed={feed}
          />
        </div>

        <div className={styles.articles}>
          <Feed
            rightBorder
            articles={selectedFeed.articles}
            onSelectArticle={onSelectArticle}
          />
        </div>
      </div>
    </div>
  );
};
