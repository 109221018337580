import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import radioLogo from 'assets/media/radio-logo.jpg';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { RadioAndTvIcon } from 'assets/icons/brikks/RadioAndTvIcon';
import { LinkBrikkContent } from 'components/brikks/sub-components/LinkBrikkContent';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const Radio = ({ position, status, code, name }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  return (

    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<RadioAndTvIcon />}
            heading="radioBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <CircularImage
              src={radioLogo}
              alt="Radio station logo"
            />

            <LinkBrikkContent link="svt.se" />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="Radio Link"
              to="https://svt.se/"
              brikkNameIntl="Public service"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkInfoModal
        title="Radio info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />
    </>

  );
};
