import React, { useContext } from 'react';
import classNames from 'classnames';
import { SwedishFlag } from 'assets/icons/SwedishFlag';
import { EnglishFlag } from 'assets/icons/EnglishFlag';
import { SWEDISH, ENGLISH } from 'services/user/locale/utils';
import { AppContext } from 'AppContext';
import { Radio } from 'components/form-elements/radios/Radio';
import styles from './styles.module.scss';

export const LanguagePicker = ({ column }) => {
  const { locale: { locale, setLocale } } = useContext(AppContext);

  return (
    <div className={classNames(
      styles.languagePicker, {
        [styles.column]: column,
      },
    )}
    >
      <div className={styles.option}>
        <SwedishFlag />

        <Radio
          toggle
          name="swedish"
          checked={locale === SWEDISH}
          onChange={() => setLocale(SWEDISH)}
        />
      </div>

      <div className={styles.option}>
        <EnglishFlag />

        <Radio
          toggle
          name="english"
          checked={locale === ENGLISH}
          onChange={() => setLocale(ENGLISH)}
        />
      </div>
    </div>
  );
};
