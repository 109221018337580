import React from 'react';
import { Image } from 'semantic-ui-react';
import styles from './styles.module.scss';

export const CircularImage = ({ src, alt, id, objectFit, onError }) => (
  <Image
    centered
    circular
    className={styles.circularImage}
    id={id}
    src={src}
    alt={alt}
    onError={onError}
    style={{ objectFit }}
  />
);
