import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { confirmEmail, confirmEmailReset } from './actions';

export function useConfirmEmail() {
  const { isEmailConfirmed, loading, error } = useSelector(state => state.confirmEmail);

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(confirmEmailReset()), [dispatch]);

  return {
    onConfirmEmail: (email) => dispatch(confirmEmail(email)),
    isEmailConfirmed,
    loading,
    error,
  };
}
