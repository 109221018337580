import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useMutation, useQueryClient } from 'react-query';
import { deleteRSSFeedAPI } from './api';

const RSS_FEED = 'rssFeed';

export function useDeleteRSSFeed() {

  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (id) => deleteRSSFeedAPI(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RSS_FEED, tenantId]);
      },
    },
  );

  return {
    deleteRSSFeed: mutate,
    isSuccess,
    isLoading,
    isError,
    error: error?.message,
  };
}
