import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { submitMaintenanceOrder, submitMaintenanceOrderReset } from './actions';
import { mapOrderToAPIFormat } from './utils';

export function useSubmitMaintenanceOrder() {
  const { isSuccessful, loading, error } = useSelector(state => state.submitMaintenanceOrder);

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(submitMaintenanceOrderReset()), [dispatch]);

  const onSubmitOrder = useCallback((orderRef, maintenanceOrder) => {
    dispatch(submitMaintenanceOrder(orderRef, mapOrderToAPIFormat(maintenanceOrder)));
  }, [dispatch]);

  return {
    isSuccessful,
    loading,
    error,
    onSubmitOrder,
  };
}
