import React from 'react';
import { useHistory } from 'react-router-dom';
import { useOrderModes } from 'services/order/order-mode/hook';
import { Text } from 'components/typography/Text';
import { Button } from 'components/form-elements/buttons/Button';
import { CloseCircleIcon } from 'assets/icons/CloseCircleIcon';
import { BackArrowIcon } from 'assets/icons/BackArrowIcon';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const OrderMode = ({ isOrderList }) => {

  const history = useHistory();
  const {
    handleOrderMode,
    handleProjectName,
    projectName } = useOrderModes();

  const handleOrderModeChange = () => {
    handleOrderMode();
    history.push('/');
  };

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <Text
          className={styles.title}
          secondary
          intl="chooseYourBrikks"
          size={20}
        />
        <div>
          <input
            name="projectName"
            placeholder="Project name"
            className={styles.customInput}
            defaultValue={projectName}
            onChange={handleProjectName}
          />
        </div>
        <div className={styles.statusText}>
          <span className={classNames(styles.blueCircle, styles.grayCircle)} />
          <Text
            className={styles.statusText}
            secondary
            intl="conceptAndDesign"
            size={16}
          />
        </div>
        <div className={styles.statusText}>
          <span className={styles.blueCircle} />
          <Text
            className={styles.statusText}
            secondary
            intl="readyToIntegrate"
            size={16}
          />
        </div>

        {isOrderList
          ? (
            <>
              <Button
                intl="backToGrid"
                className={styles.button}
                onClick={() => history.push('/')}
              >
                <BackArrowIcon />
              </Button>
              <div onClick={handleOrderModeChange}>
                <CloseCircleIcon />
              </div>
            </>
          )
          : (
            <>
              <Button
                intl="viewSelectedBrikks"
                className={styles.button}
                onClick={() => history.push('/order')}
              />
              <div onClick={handleOrderMode}>
                <CloseCircleIcon />
              </div>
            </>
          )}
      </div>
    </div>
  );
};
