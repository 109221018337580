import vidingehemLogoWhite from 'assets/media/vidingehem/logo-white.svg';
import vidingehemLogoDark from 'assets/media/vidingehem/logo-dark.svg';
import vxbFavicon from 'assets/media/vidingehem/favicon.ico';
import vxbWelcome from 'assets/media/vidingehem/welcome.jpg';
import vxbLivingRoom from 'assets/media/vidingehem/livingroom.png';
import vxbServiceStatusSrc from 'assets/media/vidingehem/service-status.jpg';
import vxbServiceReport from 'assets/media/vidingehem/service-report.png';
import vxbHousingQueueSrc from 'assets/media/vidingehem/housing-queue.png';
import vxbEnglish from 'translations/vxb/english.json';
import vxbSwedish from 'translations/vxb/swedish.json';
import { VXBOnboarding } from 'pages/onboarding/VXBOnboarding';
import vxbThemes from 'global/tenant-themes/vxb-themes.json';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { VXBLivingInfo } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/VXBLivingInfo';
import { WHITE_BG } from 'services/user/settings/utils';

export const vxbTenant = {
  assets: {
    logoWhite: vidingehemLogoWhite,
    logoDark: vidingehemLogoDark,
    favicon: vxbFavicon,
    welcome: vxbWelcome,
    livingRoom: vxbLivingRoom,
  },
  intl: {
    english: vxbEnglish,
    swedish: vxbSwedish,
  },
  components: {
    onboarding: VXBOnboarding,
  },
  initialBackground: WHITE_BG,
  themes: vxbThemes,
  sizes: {
    brikk: '300px',
    'brikks-spacing': '12px',
  },
  multiGrids: [MultiGrid.Home, MultiGrid.Neighbourhood, MultiGrid.Society, MultiGrid.Sustainability, MultiGrid.Store],
  brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A13', 'A19', 'B09', 'C07', 'D01', 'D11', 'H10'], 
  city: 'Växjö',
  openweathermapLat: '56.8777',
  openweathermapLon: '14.8091',
  officeLocation: 'https://www.hitta.se/kartan?s=06646364',
  housingQueue: {
    link: 'https://www.boplats.vaxjo.se/Mina-sidor.aspx',
    previewImage: vxbHousingQueueSrc,
  },
  internetProvider: {
    link: 'https://www.wexnet.net/login.aspx',
    linkPreview: 'wexnet.net',
  },
  myHome: {
    components: {
      livingInfo: VXBLivingInfo,
    },
    generalInfo: {
      link: 'https://www.vidingehem.se/',
      title: 'Vidingehem',
    },
  },
  serviceStatus: {
    assets: {
      modalImage: vxbServiceStatusSrc,
    },
  },
  serviceReport: {
    assets: {
      previewImage: vxbServiceReport,
    },
  },
  upgrade: {
    links: {
      info: 'https://www.vidingehem.se/hyresgast/ditt-boende/din-lagenhet/lagenhetsunderhall.html',
    },
  },
  rpName: 'vaxjobostader-ab',
  gaTracking: 'UA-169816085-1',
};
