import { isValidPersonalNumber } from 'utils/validation/bankID';
import { getTenantIDBasedOnURL } from 'global/tenant-config';

const removeBrikks = ['A03', 'A09'];
const tenantId = getTenantIDBasedOnURL().toLowerCase();

export const validateBrikksByPersonalNumber = (brikks, personalNumber) => {
  const isPersonalNumberValid = isValidPersonalNumber(personalNumber);
  const isTenantVxb = tenantId.includes('vxb');

  if (!isPersonalNumberValid && isTenantVxb) {
    return brikks.filter((brikk) => !removeBrikks.includes(brikk.id));
  }

  return brikks;
};
