import React from 'react';
import { CircledArrowIcon } from 'assets/icons/CircledArrowIcon';
import styles from './styles.module.scss';

export const CircledArrowTop = () => (
  <div className={styles.CircledArrowTop}>
    <CircledArrowIcon
      primary
      down
      x2
    />
  </div>
);
