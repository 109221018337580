export const UserAvatarActions = {
  GET_AVATAR: '@user/avatar/get',
  GET_AVATAR_SUCCESS: '@user/avatar/get/success',
  GET_AVATAR_FAIL: '@user/avatar/get/fail',

  UPLOAD_AVATAR: '@user/avatar/upload',
  UPLOAD_AVATAR_SUCCESS: '@user/avatar/upload/success',
  UPLOAD_AVATAR_FAIL: '@user/avatar/upload/fail',
};

export function getUserAvatar() {
  return {
    type: UserAvatarActions.GET_AVATAR,
  };
}

export function getUserAvatarSuccess(avatar) {
  return {
    type: UserAvatarActions.GET_AVATAR_SUCCESS,
    payload: avatar,
  };
}

export function getUserAvatarFail(error) {
  return {
    type: UserAvatarActions.GET_AVATAR_FAIL,
    payload: error,
  };
}

export function uploadUserAvatar(avatar) {
  return {
    type: UserAvatarActions.UPLOAD_AVATAR,
    payload: avatar,
  };
}

export function uploadUserAvatarSuccess(avatar) {
  return {
    type: UserAvatarActions.UPLOAD_AVATAR_SUCCESS,
    payload: avatar,
  };
}

export function uploadUserAvatarFail(error) {
  return {
    type: UserAvatarActions.UPLOAD_AVATAR_FAIL,
    payload: error,
  };
}
