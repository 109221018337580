import React from 'react';
import { Grid, Message, Card } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import PageHeader from '../../components/headers/PageHeader';
import styles from './LogIn/styles.module.scss';

const ChangePasswordConfirmation = (props) => {
  const { history } = props;

  return (
    <>
      <IntlPageTitle intlTitle="passwordRecoverySuccess" />

      <PageHeader
        transparent
        noLogin
      />
      <Grid
        textAlign="center"
        verticalAlign="middle"
        className={classNames('brikks-auth login', styles.login)}
      >

        <Grid.Column className={classNames('auth-wrapper', styles.grid)}>
          <Card raised>

            <div className="image-column" />

            <div className="form-column">

              <Message positive>

                <Message.Header>
                  <p>
                    <FormattedMessage id="passwordRecoverySuccess" />
                  </p>
                  <p>
                    <a
                      href="/login"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push('/login');
                      }}
                    >
                      <FormattedMessage id="backToLogin" />
                    </a>
                  </p>
                </Message.Header>

              </Message>
            </div>
          </Card>
        </Grid.Column>

      </Grid>
    </>
  );
};

export default ChangePasswordConfirmation;
