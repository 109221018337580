import React from 'react';
import classNames from 'classnames';
import { CartIcon as CartIconSVG } from 'assets/icons/CartIcon';
import styles from './styles.module.scss';

export const CartIcon = () => (
  <div
    className={classNames(
      styles.cartIcon,
    )}
  >
    <CartIconSVG />
  </div>
);
