import React from 'react';
import { DimmerIcon, DishWasherIcon, DrayerIcon, KeysIcon, WashingMachineIcon } from 'assets/icons/upgrades';

export const upgrades = [
  {
    id: 1,
    name: 'New set of keys',
    baseCost: 3000,
    monthlyCost: null,
    deliveryDate: '2021-09-18',
    status: 'available',
    icon: <KeysIcon />,
  },
  {
    id: 2,
    name: 'Washing machine',
    baseCost: null,
    monthlyCost: '-',
    examinationDate: '-',
    status: 'upcoming',
    icon: <WashingMachineIcon />,
  },
  {
    id: 3,
    name: 'Dryer',
    baseCost: null,
    monthlyCost: 30,
    deliveryDate: '2021-09-18',
    status: 'available',
    icon: <DrayerIcon />,
  },
  {
    id: 4,
    name: 'Dish washer',
    baseCost: '-',
    monthlyCost: null,
    deliveryDate: '-',
    status: 'not-available',
    icon: <DishWasherIcon />,
  },
  {
    id: 5,
    name: 'Dimmer',
    baseCost: 750,
    monthlyCost: null,
    deliveryDate: '2021-09-18',
    status: 'available',
    icon: <DimmerIcon />,
  },
];
