import React from 'react';
import { Text } from 'components/typography/Text';
import { Heading } from 'components/typography/Heading';
import { Image as SemanticImage } from 'semantic-ui-react';
import { ShouldRender } from 'components/ShouldRender';
import { FormattedMessage } from 'react-intl';
import { useAssets } from 'services/assets';
import { PDFPreview } from 'components/media/PDFPreview';
import styles from './styles.module.scss';

export const GeneralInfo = ({ contentHeader, intl, intlSecondHalf, contentImage, name, category }) => {

  const { assets } = useAssets();
  const url = assets?.[category]?.find(item => item.name === name)?.url;

  return (
    <>
      <ShouldRender condition={!!contentHeader}>
        <Heading
          red
          size={25}
          intl={contentHeader}
        />
      </ShouldRender>

      <ShouldRender condition={!!contentImage}>
        <SemanticImage
          src={contentImage}
          className={styles.image}
        />
      </ShouldRender>

      <ShouldRender condition={!!intl}>
        <Text
          size={16}
          element="span"
          className={styles.generalInfoContent}
        >
          <FormattedMessage
            id={intl}
            values={{
              bold: (...chunks) => (
                <Text
                  element="span"
                  bold
                  large
                >
                  {chunks}
                </Text>
              ),
              header: (...chunks) => (
                <Text
                  element="span"
                  bold
                  size={25}
                  red
                  large
                >
                  {chunks}
                </Text>
              ),
              subheader: (...chunks) => (
                <Text
                  element="span"
                  size={22}
                  thin
                  gray
                  large
                >
                  {chunks}
                </Text>
              ),
              italic: (...chunks) => (
                <Text
                  element="span"
                  italic
                  dark
                  bold
                  size={18}
                >
                  {chunks}
                </Text>
              ),
              a: (...chunks) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.riksbyggen.se"
                >
                  {chunks}
                </a>
              ),
              email: (...chunks) => (
                <a
                  href={`mailto:${chunks}`}
                >
                  {chunks}
                </a>
              ),
              number: (...chunks) => (
                <a
                  href={`tel:${chunks}`}
                >
                  {chunks}
                </a>
              ),
              facebook: (...chunks) => (
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Riksbyggen"
                  target="_blank"
                >
                  {chunks}
                </a>
              ),
              a2: (...chunks) => (
                <a
                  rel="noopener noreferrer"
                  href="https://www.riksbyggen.se/kundtjanst"
                  target="_blank"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Text>
      </ShouldRender>

      <ShouldRender condition={!!intlSecondHalf}>
        <Text
          size={16}
          element="span"
        >
          <FormattedMessage
            id={intlSecondHalf}
            values={{
              bold: (...chunks) => (
                <Text
                  element="span"
                  bold
                  large
                >
                  {chunks}
                </Text>
              ),
              header: (...chunks) => (
                <Text
                  element="span"
                  bold
                  size={25}
                  red
                  large
                >
                  {chunks}
                </Text>
              ),
              subheader: (...chunks) => (
                <Text
                  element="span"
                  size={22}
                  thin
                  gray
                  large
                >
                  {chunks}
                </Text>
              ),
              italic: (...chunks) => (
                <Text
                  element="span"
                  italic
                  dark
                  bold
                  size={18}
                >
                  {chunks}
                </Text>
              ),
              a: (...chunks) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.riksbyggen.se"
                >
                  {chunks}
                </a>
              ),
              email: (...chunks) => (
                <a
                  href={`mailto:${chunks}`}
                >
                  {chunks}
                </a>
              ),
              number: (...chunks) => (
                <a
                  href={`tel:${chunks}`}
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Text>
      </ShouldRender>

      <ShouldRender condition={!!name}>
        <PDFPreview
          key={name}
          downloadName={name}
          url={url}
          showInDocuments={false}
        />
      </ShouldRender>

    </>
  );
};
