import { authorize, putRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { getUserAttributesFromToken } from 'utils/local-storage/attributes';

const url = `${AWS_API_URL}/user-attributes`;

export async function getLocaleAPI() {
  const userAttributes = await getUserAttributesFromToken();
  return userAttributes.locale;
}

export async function updateLocaleAPI(locale) {
  return putRequest(url, locale, await authorize());
}
