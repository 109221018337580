import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { ProcessingLoader } from '../../loaders/ProcessingLoader';
import styles from './styles.module.scss';

export const InfoMessage = ({ loading, message }) => (
  <div className={styles.infoMessage}>
    {loading ? <ProcessingLoader /> : <InfoIcon />}

    <FormattedMessage id={message} />
  </div>
);
