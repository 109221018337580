import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { ShouldRender } from 'components/ShouldRender';
import { useTheme } from 'global/themes';
import styles from './styles.module.scss';

export const InnerModal = ({ isOpen, onClose = () => {}, children, className }) => {
  const ref = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    }
  }, [ref, onClose]);

  const { theme } = useTheme();

  return (
    <ShouldRender condition={isOpen}>
      <div
        className={styles.outerLayer}
        onClick={handleClickOutside}
      >
        <div
          ref={ref}
          className={classNames(styles.innerModal, className, theme.backgroundColor)}
        >
          {children}
        </div>
      </div>
    </ShouldRender>
  );
};
