import React, { useContext } from 'react';
import { Button, Select } from 'semantic-ui-react';
import { Text } from 'components/typography';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { Form } from 'components/form-elements/Form';
import { Grid } from 'components/layout/Grid';
import { FormattedMessage } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { AppContext } from 'AppContext';
import { terminateReasons } from
  'components/brikks/home/TerminateAgreement/components/TerminateDetails/components/TerminateReasons/data';
import { LabeledDatepicker } from 'components/form-elements/pickers/LabeledDatepicker';
import { LKF } from 'global/tenant-config/tenants';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const TerminateReasons = ({ onClose, setTerminationDetails, finalTerminationDate }) => {

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onSubmit',
  });

  const tenantId = getTenantIDBasedOnURL().toLowerCase();
  const { locale: { locale } } = useContext(AppContext);
  const reasons = terminateReasons[tenantId]?.[locale]?.map(
    reason => ({ key: reason, value: reason, text: reason }
    ),
  );

  const onSubmit = (terminateDetails) => {
    setTerminationDetails(terminateDetails);
    onClose();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ShouldRender condition={tenantId !== LKF}>
        <Grid
          oneColumn
          noPadding
        >
          <Text
            bold
            xxl1
            uppercase
            intl="movingReason"
          />

          <Controller
            name="reason"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                error={errors.reason}
                options={reasons}
                value={field.value}
                onChange={(e, { value }) => field.onChange(value)}
              />
            )}
          />
        </Grid>
      </ShouldRender>

      <Grid
        oneColumn
        noPadding
        style={{
          paddingTop: '15px',
        }}
      >
        <Text
          bold
          xxl1
          uppercase
          intl="earlierMovingOutDate"
        />

        <Controller
          name="earlierRequestedDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <LabeledDatepicker
              intl="requestMoveOut"
              minDate={new Date()}
              maxDate={finalTerminationDate}
              onChange={onChange}
              selectedDate={value}
            />
          )}
        />
      </Grid>

      <Grid
        oneColumn
        noPadding
        style={{
          paddingTop: '15px',
        }}
      >
        <Text
          bold
          xxl1
          uppercase
          intl="newAddress"
        />

        <Controller
          name="address"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <LabeledInput
              value={field.value}
              onChange={field.onChange}
              labelIntl="address"
            />
          )}
        />

        <Grid
          twoEqualColumns
          noPadding
        >
          <Controller
            name="postcode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <LabeledInput
                value={field.value}
                onChange={field.onChange}
                labelIntl="postcode"
              />
            )}
          />

          <Controller
            name="city"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <LabeledInput
                value={field.value}
                onChange={field.onChange}
                labelIntl="city"
              />
            )}
          />
        </Grid>
      </Grid>

      <Button
        className={styles.button}
        name="submit-terminate-reason"
        secondary
        floated="right"
        type="submit"
      >
        <FormattedMessage id="send" />
      </Button>
    </Form>
  );
};
