import React, { useContext } from 'react';
import classNames from 'classnames';
import { GridContext } from 'components/layout/Grid';
import { Header } from './components/Header';
import styles from './styles.module.scss';

export const Column = ({ justifySelf = 'start', className, twoRow, children, fullHeight, noOverflow }) => {
  const { brikkLayout, twoColumns, transparent } = useContext(GridContext);

  return (
    <div
      style={{ justifySelf }}
      className={classNames(styles.column, {
        [styles.twoColumns]: twoColumns,
        [styles.brikkLayout]: brikkLayout,
        [styles.twoRow]: twoRow,
        [styles.transparent]: transparent,
        [styles.fullHeight]: fullHeight,
        [styles.noOverflow]: noOverflow,
      }, className)}
    >
      {children}
    </div>
  );
};

Column.Header = Header;
