import React from 'react';
import { Text as GlobalText } from 'components/typography/Text';
import styles from './styles.module.scss';

export const Text = ({ dark, intl, size }) => (
  <div className={styles.text}>
    <GlobalText
      dark={dark}
      underline
      size={size}
      intl={intl}
    />
  </div>
);
