import React, { useContext } from 'react';
import { useMaintenance } from 'services/maintenance/planned-maintenance/hook';
import { unionBy, orderBy } from 'lodash';
import { Dropdown } from 'components/dropdowns/Dropdown';
import { ItemContent } from
  'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeItems/components/ItemContent';
import { Brikk } from 'components/Brikk';
import { ItemName } from
  'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeItems/components/ItemName';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { formatCurrency } from 'utils/format';
import styles from './styles.module.scss';

export const UpgradeItems = () => {
  const { maintenance: { plannedMaintenance } } = useMaintenance();

  const { selectedSurface } = useContext(UpgradeContext);

  const sortedMaintenance = orderBy(plannedMaintenance, ['type', 'residualValue'], ['asc', 'asc']);
  const rooms = unionBy(sortedMaintenance.map(item => ({ type: item.type, residualValue: item.residualValue })), 'type');

  return (
    <Brikk.Heading heading="chooseAction">
      <div className={styles.UpgradeItems}>
        {rooms.map(room => (
          <Dropdown
            key={room.type}
            open={selectedSurface?.type === room.type} // Open selected room when redirected from step 1.
          >
            <Dropdown.Item>
              <ItemName
                residualValue={formatCurrency(room.residualValue)}
                name={room.type}
              />
            </Dropdown.Item>

            <Dropdown.Content>
              {plannedMaintenance
                .filter(maintenanceItem => maintenanceItem.type === room.type)
                .map((maintenanceItem, index) => (
                  <div
                    key={`${maintenanceItem.unit}-${index}`}
                    className={styles.roomTypes}
                  >
                    <Dropdown
                      subDropdown
                      open={selectedSurface?.surface === maintenanceItem.surface} // Open selected surface when redirected from step 1.
                    >
                      <Dropdown.SubItem>
                        <ItemName
                          residualValue={formatCurrency(maintenanceItem.residualValue)}
                          name={maintenanceItem.currentStatus}
                        />
                      </Dropdown.SubItem>

                      <Dropdown.Content>
                        <ItemContent maintenanceItem={maintenanceItem} />
                      </Dropdown.Content>
                    </Dropdown>
                  </div>
                ))}
            </Dropdown.Content>
          </Dropdown>
        ))}
      </div>
    </Brikk.Heading>
  );
};
