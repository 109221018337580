import React, { createContext, useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { AppContext } from 'AppContext';
import { useInitPassReset } from 'services/auth/init-pass-reset/hook';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { HOME_PAGE } from 'global/routes';
import { Redirect } from 'react-router-dom';
import { PageLoader } from 'components/loaders/PageLoader';
import { ActivateAccount } from 'pages/auth/LogIn/components/ActivateAccount';
import { LoginForm } from 'pages/auth/LogIn/components/LoginForm';

export const LoginContext = createContext(null);

const LogIn = () => {
  const { isAuthenticated, authLoading } = useContext(AppContext);
  const [shouldActivateAccount, setShouldActivateAccount] = useState(false);
  const [email, setEmail] = useState('');

  const { isInitSuccessful } = useInitPassReset();

  useEffect(() => {
    if (isInitSuccessful) {
      setShouldActivateAccount(true);
    }
  }, [isInitSuccessful, setShouldActivateAccount]);

  if (authLoading) {
    return <PageLoader />;
  }

  return (
    <LoginContext.Provider value={{
      email,
      setEmail,
      setShouldActivateAccount,
    }}
    >
      {isAuthenticated
        ? <Redirect to={HOME_PAGE} />
        : (
          <BasicPageLayout intlTitle="login">
            <Form size="large">
              {shouldActivateAccount ? <ActivateAccount /> : <LoginForm />}
            </Form>
          </BasicPageLayout>
        )}
    </LoginContext.Provider>
  );

};

export default LogIn;
