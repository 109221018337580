import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { ShouldRender } from 'components/ShouldRender';
import { Placeholder } from 'semantic-ui-react';
import { useHasValueChanged } from 'utils/hooks/useHasValueChanged';
import styles from './styles.module.scss';

export const Image = ({ src, alt = 'image', circle, className, style, onClick = () => {} }) => {
  const [shouldRender, setShouldRender] = useState(src);
  const [isLoading, setIsLoading] = useState(true);

  const hasSrcChanged = useHasValueChanged(src);

  useLayoutEffect(() => {
    if (hasSrcChanged) {
      setIsLoading(true);
    }
  }, [setIsLoading, hasSrcChanged]);

  if (shouldRender) {
    return (
      <>
        <img
          className={classNames(
            styles.image,
            className,
            circle && styles.circle,
            isLoading && styles.hide,
          )}
          src={src}
          alt={alt}
          onClick={onClick}
          onLoad={() => setIsLoading(false)}
          onError={() => setShouldRender(false)}
          style={style}
        />

        <ShouldRender condition={isLoading}>
          <div className={classNames(styles.loader, className)}>
            <Placeholder />
          </div>
        </ShouldRender>
      </>
    );
  }

  return <div className={classNames(styles.placeholder, circle && styles.circle, className)} />;
};
