import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { FaceIcon } from 'assets/icons/brikks/FaceIcon';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { Image } from 'components/media/Image';
import { DemoModal } from 'components/modals/DemoModal';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import brikkModalSrc from './assets/community-modal.png';
import brikkPreviewSrc from './assets/community-brikk-preview.png';

export const Community = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<FaceIcon />}
            heading="communityBrikk"
            brikkKey={code} 
            status={status}
          />

          <Brikk.Content center>
            <Image src={brikkPreviewSrc} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="community"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Community info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <DemoModal
        isOpen={isModalOpen}
        heading="community"
        onClose={() => setIsModalOpen(false)}
      >
        <Image src={brikkModalSrc} />
      </DemoModal>
    </>
  );
};
