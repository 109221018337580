import React, { useContext, useEffect, useState } from 'react';
import { useMaintenanceProducts } from 'services/maintenance/maintenance-products/hook';
import { Text } from 'components/typography/Text';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { useHasValueChanged } from 'utils/hooks/useHasValueChanged';
import { ProductImages } from './components/ProductImages';
import { ProductDescription } from './components/ProductDescription';
import { ProductCost } from './components/ProductCost';
import styles from './styles.module.scss';

export const ProductDetails = () => {
  const {
    selectedProduct: { code },
    selectedSurface,
    selectedPurchases,
    onAddProduct,
    setSelectedProduct,
  } = useContext(UpgradeContext);

  const { products } = useMaintenanceProducts(selectedSurface?.type, selectedSurface?.unit, selectedSurface?.measure);

  const [selectedProductIndex, setSelectedProductIndex] = useState(
    products.findIndex(product => product.code === code),
  );

  const selectedProduct = products[selectedProductIndex];

  const hasProductChanged = useHasValueChanged(selectedProduct);

  useEffect(() => {
    if (hasProductChanged) {
      setSelectedProduct(selectedProduct);
    }
  }, [selectedProduct, hasProductChanged, setSelectedProduct]);

  return (
    <div className={styles.productDetails}>
      <ProductImages
        selectedProduct={selectedProduct}
        products={products}
        selectedProductIndex={selectedProductIndex}
        setSelectedProductIndex={setSelectedProductIndex}
      />

      <div className={styles.productContent}>
        <Text
          bold
          size={22}
          uppercase
          intl="product"
          className={styles.heading}
        />

        <Text
          lightGray
          size={28}
          bold
          className={styles.productName}
        >
          {selectedProduct?.name}
        </Text>

        <ProductDescription selectedProduct={selectedProduct} />

        <ProductCost
          onAddProduct={onAddProduct}
          selectedPurchases={selectedPurchases}
        />
      </div>
    </div>
  );
};
