import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Text } from 'components/typography/Text';
import { FocusableElement } from 'components/FocusableElement';
import { ReactComponent as MarketIcon } from 'assets/icons/market.svg';
import { ReactComponent as EconomyIcon } from 'assets/icons/economy.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/security.svg';
import { ReactComponent as SmartnessIcon } from 'assets/icons/smart-home.svg';
import { ReactComponent as SustainabilityIcon } from 'assets/icons/sustainability.svg';
import { ReactComponent as SocietyIcon } from 'assets/icons/society.svg';
import { ReactComponent as BuildingsIcon } from 'assets/icons/buildings.svg';
import { ReactComponent as HousesIcon } from 'assets/icons/houses.svg';
import { useMultiGrid } from 'services/multi-grid/hook';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import styles from './styles.module.scss';

export const MenuItem = ({ multiGrid }) => {
  const { selectedGrid, setMultiGrid } = useMultiGrid();

  const Icon = useMemo(() => {
    switch (multiGrid) {
      case MultiGrid.Home:
        return BuildingsIcon;
      case MultiGrid.Neighbourhood:
        return HousesIcon;
      case MultiGrid.Society:
        return SocietyIcon;
      case MultiGrid.Sustainability:
        return SustainabilityIcon;
      case MultiGrid.Smartness:
        return SmartnessIcon;
      case MultiGrid.Security:
        return SecurityIcon;
      case MultiGrid.Finances:
        return EconomyIcon;
      case MultiGrid.Store:
        return MarketIcon;
      default:
        return BuildingsIcon;
    }
  }, [multiGrid]);

  return (
    <FocusableElement
      className={classNames(
        styles.menuItem, {
          [styles.active]: selectedGrid === multiGrid,
        },
      )}
      onClick={() => setMultiGrid(multiGrid)}
    >
      <div className={styles.itemContent}>
        <Icon />

        <Text
          white
          size={14}
          intl={multiGrid}
        />
      </div>
    </FocusableElement>
  );
};
