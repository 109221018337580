/*
 User's locale
 */

import { ENGLISH, SWEDISH } from './utils';
import { LocaleActions } from './actions';

const initialState = {
  locale: SWEDISH,
  calledOnce: false,
};

export function localeReducer(state = initialState, action) {
  switch (action.type) {
    case LocaleActions.GET_LOCALE:
      return {
        ...state,
        calledOnce: true,
      };
    case LocaleActions.GET_LOCALE_SUCCESS: {
      let locale = action.payload;
      if (locale !== ENGLISH && locale !== SWEDISH) {
        locale = SWEDISH;
      }

      return {
        ...state,
        locale,
      };
    }
    case LocaleActions.UPDATE_BROWSER_LOCALE:
    case LocaleActions.UPDATE_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
}
