import React from 'react';
import classNames from 'classnames';
import { Text } from 'components/typography/Text';
import { useMultiGrid } from 'services/multi-grid/hook';
import { FocusableElement } from 'components/FocusableElement';
import styles from './styles.module.scss';

export const MultiGridItem = ({ grid }) => {
  const { selectedGrid, setMultiGrid } = useMultiGrid();

  return (
    <FocusableElement
      className={classNames(
        styles.item,
        {
          [styles.active]: grid === selectedGrid,
        },
      )}
      onClick={() => setMultiGrid(grid)}
    >
      <Text
        size={14}
        bold
        intl={grid}
      />
    </FocusableElement>
  );
};
