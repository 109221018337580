import React from 'react';
import { Draggable } from 'components/drag-and-drop';
import { useOrderModes } from 'services/order/order-mode/hook';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { DragIndicatorIcon } from 'assets/icons/DragIndicatorIcon';
import { CustomCard } from './CustomCard';
import styles from './styles.module.scss';

export const DraggableCard = ({ position, code, name, status }) => {
  const { removeBrikk } = useOrderModes();

  return (
    <Draggable position={position}>
      <CustomCard>
        <div
          className={styles.list}
          key={code}
        >
          <div
            className={styles.fixWidth}
            onClick={() => removeBrikk(code)}
          >
            <CloseIcon />
          </div>
          <div className={styles.columns}>
            {code} {name}
          </div>
          <div className={styles.columns}>{status}</div>
          <div className={styles.dragIcon}>
            <DragIndicatorIcon />
          </div>
        </div>
      </CustomCard>
    </Draggable>
  );
};
