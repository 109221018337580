export const CheckListOptions = {
  TERMINATE_AGREEMENTS: {
    menu: 'terminateAgreements',
    submenus: 'agreements',
  },
  KEYS: {
    menu: 'keys',
  },
  PACKING: {
    menu: 'packing',
  },
  CLEANING: {
    menu: 'cleaning',
  },
  CHANGE_ADDRESS: {
    menu: 'changeAddress',
  },
  RECYCLING: {
    menu: 'recycling',
  },
  TEMPORARY_STORAGE: {
    menu: 'temporaryStorage',
  },
};
