export const ResetPassActions = {
  RESET_PASS: '@resetPass/',
  RESET_PASS_SUCCESS: '@resetPass/success',
  RESET_PASS_FAIL: '@resetPass/fail',

  RESET_PASS_RESET: '@resetPass/reset',
};

export function resetPass(email, password, code) {
  return {
    type: ResetPassActions.RESET_PASS,
    payload: { email, password, code },
  };
}

export function resetPassSuccess() {
  return {
    type: ResetPassActions.RESET_PASS_SUCCESS,
  };
}

export function resetPassFail(error) {
  return {
    type: ResetPassActions.RESET_PASS_FAIL,
    payload: error,
  };
}

export function resetPassReset() {
  return {
    type: ResetPassActions.RESET_PASS_RESET,
  };
}
