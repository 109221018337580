import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { GeneralInfoOptions } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LivingGeneralInfo';
import { LivingInfoOptions } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/VXBTabOptions';

export const VXBLivingInfo = () => (
  <MultiTabMenu
    defaultTab="yourApartment"
    tabs={{
      yourApartment: { title: 'yourApartment', menus: LivingInfoOptions },
      generalInfo: { title: 'generalInfo', menus: GeneralInfoOptions },
    }}
  />
);
