import React from 'react';
import classNames from 'classnames';
import { Card } from '../Card';
import styles from './styles.module.scss';

export const ModalCard = ({ className, children }) => (
  <Card className={classNames(styles.modalCard, className)}>
    {children}
  </Card>
);
