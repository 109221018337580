import { takeLatest, put, call } from 'redux-saga/effects';
import { getUserAttributes } from 'utils/local-storage/attributes';
import { getUserSettingsSuccess, UserSettingsActions } from './actions';
import { getUserSettingsAPI, updateUserSettingsAPI } from './api';
import { UserSettings } from './models/UserSettings';
import { defaultBrikksOrder, trackUpdateSettingsEvent } from './utils';

function* getUserSettingsEffect() {
  const browserUserSettings = getUserAttributes();

  try {
    let backendSettings = yield call(getUserSettingsAPI);
    if (backendSettings) {
      if (backendSettings.brikksOrder) {
        const parsedBrikksOrder = JSON.parse(backendSettings.brikksOrder);
        if (!Array.isArray(parsedBrikksOrder)) { //skip if using old (pre multigrid) brikks order structure
          backendSettings.brikksOrder = parsedBrikksOrder;
        } else {
          backendSettings.brikksOrder = defaultBrikksOrder;
        }
      }
    } else {
      backendSettings = {};
    }

    const userSettings = new UserSettings({ ...browserUserSettings, ...backendSettings });

    yield put(getUserSettingsSuccess(userSettings));
  } catch (e) {
    yield put(getUserSettingsSuccess({ ...browserUserSettings, onboardingPassed: browserUserSettings.onboarding_passed }));
  }
}

function* updateUserSettingsEffect({ payload }) {
  trackUpdateSettingsEvent(payload);
  try {
    yield call(updateUserSettingsAPI, payload);
  } catch (e) {
    /* eslint-disable no-console */
    console.log(e);
  }
}

export const userSettingsEffects = [
  takeLatest(UserSettingsActions.GET_USER_SETTINGS, getUserSettingsEffect),
  takeLatest(UserSettingsActions.UPDATE_USER_SETTINGS, updateUserSettingsEffect),
];
