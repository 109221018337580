import React, { useContext } from 'react';
import { Text } from 'components/typography/Text';
import styles from 'components/brikks/home/Upgrades/components/UpgradesModal/components/UpgradesItem/styles.module.scss';
import { CheckoutButton } from 'components/form-elements/buttons/CheckoutButton';
import { UpgradesModalContext } from 'components/brikks/home/Upgrades/components/UpgradesModal/index';

export const UpgradesItem = ({ upgrade }) => {
  const { selectedUpgrades, setSelectedUpgrades } = useContext(
    UpgradesModalContext,
  );

  const handleOnClick = () => {
    if (!selectedUpgrades.some(item => item.id === upgrade.id)) {
      setSelectedUpgrades([...selectedUpgrades, upgrade]);
    }
  };

  return (
    <div
      className={styles.upgrade}
    >
      <div className={styles.icon}>
        {upgrade.icon}
      </div>

      <div className={styles.description}>
        <Text
          size={14}
          bold
        >
          {upgrade.name}
        </Text>

        {
          upgrade.baseCost
          && (
            <div className={styles.inline}>
              <Text
                size={14}
                intl="baseCostValue"
                intlValues={{ value: `${upgrade.baseCost} kr` }}
              />
            </div>
          )
        }

        {
          upgrade.monthlyCost
          && (
            <div className={styles.inline}>
              <Text
                size={14}
                intl="monthlyCostValue"
                intlValues={{ value: `${upgrade.monthlyCost} kr` }}
              />
            </div>
          )
        }

        {
          upgrade.deliveryDate
          && (
            <div className={styles.inline}>
              <Text
                size={14}
                intl="deliveryDateValue"
                intlValues={{ value: upgrade.deliveryDate }}
              />
            </div>
          )
        }

        {
          upgrade.examinationDate
          && (
            <div className={styles.inline}>
              <Text
                size={14}
                intl="examinationDateValue"
                intlValues={{ value: upgrade.examinationDate }}
              />
            </div>
          )
        }

        {
          {
            available: (
              <div className={styles.status}>
                <span className={styles.greenDot} />
                <Text
                  className={styles.blue}
                  intl="availableMoreInfo"
                  size={12}
                />
              </div>
            ),
            upcoming: (
              <div className={styles.status}>
                <span className={styles.yellowDot} />
                <Text
                  className={styles.blue}
                  intl="upcomingMoreInfo"
                  size={12}
                />
              </div>
            ),
            'not-available': (
              <div className={styles.status}>
                <span className={styles.redDot} />
                <Text
                  className={styles.blue}
                  intl="notAvailableMoreInfo"
                  size={12}
                />
              </div>
            ),
          }[upgrade.status]
        }
      </div>

      {['available', 'upcoming'].includes(upgrade.status) && (
        <CheckoutButton onClick={handleOnClick} />
      )}
    </div>
  );
};
