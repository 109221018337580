import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { Text } from 'components/typography/Text';
import { ReactComponent as SettingsIcon } from 'assets/icons/cog.svg';
import { FocusableElement } from '../../../../../../FocusableElement';
import styles from './styles.module.scss';

export const Settings = () => {
  const { setIsSideMenuOpen } = useContext(AppContext);

  return (
    <FocusableElement
      className={styles.settings}
      onClick={() => setIsSideMenuOpen(true)}
    >
      <div className={styles.text}>
        <Text
          size={14}
          intl="settings"
        />
      </div>

      <SettingsIcon />
    </FocusableElement>
  );
};
