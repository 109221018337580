import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from 'components/menus/SideMenu/styles.module.scss';
import { AppContext } from 'AppContext';
import { tenantConfig } from 'global/tenant-config';
import { DiscardCrossIcon } from 'assets/icons/DiscardCrossIcon';
import { ThemeSettings } from 'components/menus/SideMenu/components/ThemeSettings';
import { BackgroundSettings } from 'components/menus/SideMenu/components/BackgroundSettings';
import { LanguageSettings } from 'components/menus/SideMenu/components/LanguageSettings';
import { OnboardingSettings } from 'components/menus/SideMenu/components/OnboardingSettings';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import FocusTrap from 'focus-trap-react';

export const SideMenu = () => {
  const { isSideMenuOpen, setIsSideMenuOpen } = useContext(AppContext);

  const { ref, onClickOutside } = useClickOutside(() => setIsSideMenuOpen(false));

  const ClientTheme = tenantConfig?.components?.clientTheme;

  return (
    <FocusTrap active={isSideMenuOpen}>
      <div
        className={classNames(styles.sideMenuContainer, isSideMenuOpen && styles.open)}
        onClick={onClickOutside}
      >
        <div
          ref={ref}
          className={classNames(styles.sideMenu, isSideMenuOpen && styles.open)}
        >
          <div className={styles.header}>

            <DiscardCrossIcon
              white
              xl
              ignoreTheme
              onClick={() => setIsSideMenuOpen(false)}
            />
          </div>

          <div className={styles.settings}>
            <LanguageSettings />

            <ThemeSettings />

            <BackgroundSettings />

            {ClientTheme && <ClientTheme />}

            <OnboardingSettings />
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};
