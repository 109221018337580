import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { Grid } from 'components/layout/Grid';
import citizenHubSrc from 'assets/media/lkf/citizen-hub.png';
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';
import { LKFOnboardingRoutes } from 'pages/onboarding/LKFOnboarding/routes';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { FormattedMessage } from 'react-intl';
import { ImageGalleryOverlay } from '../ImageGalleryOverlay';

export const CitizenHub = () => {
  const { onRedirect } = useRedirect(LKFOnboardingRoutes.SCIENCE_VILLAGE);

  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <ImageGalleryOverlay
            imageSrc={citizenHubSrc}
            overlayIntl="onboardingCitizenHubTitle"
          />
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="onboardingCitizenHubHeading">
            <Text
              size={18}
            >
              <FormattedMessage
                id="onboardingCitizenHubDescription"
                values={{
                  website: (...chunks) => (
                    <a
                      rel="noopener noreferrer"
                      href="https://www.internationalcitizenhub.com"
                      target="_blank"
                    >
                      {chunks}
                    </a>
                  ),
                  facebook: (...chunks) => (
                    <a
                      rel="noopener noreferrer"
                      href="https://facebook.com/internationalcitizenhub"
                      target="_blank"
                    >
                      {chunks}
                    </a>
                  ),
                  phone: (...chunks) => (
                    <a href="tel:+46463598100">
                      {chunks}
                    </a>
                  ),
                  email: (...chunks) => (
                    <a href="mailto:lich@lund.se">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Text>
          </Brikk.Heading>
        </Grid.Column>

        <NextStep onClick={() => onRedirect()} />
      </OnboardingGrid>
    </ModalCard>
  );
};
