import React, { useContext } from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { CloudDownloadIcon } from 'assets/icons/CloudDownloadIcon';
import { EyeIcon } from 'assets/icons/EyeIcon';
import { ExternalLink } from 'components/links/ExternalLink';
import { BaseButton } from 'components/form-elements/buttons/BaseButton';
import { downloadDocument } from 'utils/download';
import { DocumentPreviewContext } from 'components/media/DocumentPreview';
import { ActionButton } from '../ActionButton';
import styles from './styles.module.scss';

export const Actions = ({ download, view }) => {
  const { url, name, downloadName } = useContext(DocumentPreviewContext);

  return (
    <div className={styles.actions}>
      <ShouldRender condition={!!download}>
        <BaseButton
          className={styles.button}
          onClick={() => downloadDocument(downloadName || name, url)}
        >
          <ActionButton
            intl="download"
          >
            <CloudDownloadIcon />
          </ActionButton>
        </BaseButton>
      </ShouldRender>

      <ShouldRender condition={!!view}>
        <ExternalLink href={url}>
          <ActionButton intl="view">
            <EyeIcon />
          </ActionButton>
        </ExternalLink>
      </ShouldRender>
    </div>
  );
};
