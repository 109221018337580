import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { SlideIcon } from 'assets/icons/SlideIcon';
import { ShouldRender } from 'components/ShouldRender';
import { FocusableElement } from 'components/FocusableElement';
import { previousMonthPeriod, nextMonthPeriod } from 'utils/water-usage/datePeriods';
import styles from './styles.module.scss';

export const MonthSelector = ({ period, setPeriod, selectedMonthLabel }) => {
  const [nextDisabled, setNextDisabled] = useState(false);

  useEffect(() => {
    const now = new Date();
    const periodDate = new Date(period.reportAfter);

    if (periodDate.getFullYear() === now.getFullYear()
      && periodDate.getMonth() === now.getMonth()) {
      setNextDisabled(true);
    } else setNextDisabled(false);
  }, [period]);

  const handleMonthDecrease = () => {
    setPeriod(previousMonthPeriod(period));
  };

  const handleMonthIncrease = () => {
    setPeriod(nextMonthPeriod(period));
  };

  return (
    <div className={styles.monthSelector}>
      <FocusableElement
        onClick={() => handleMonthDecrease()}
        className={styles.arrowButton}
      >
        <SlideIcon direction="left" />
      </FocusableElement>

      <ShouldRender condition={selectedMonthLabel.length}>
        <div className={styles.monthLabel}>
          <FormattedMessage id={selectedMonthLabel} /> {new Date(period.reportAfter).getFullYear()}
        </div>
      </ShouldRender>

      <FocusableElement
        onClick={() => handleMonthIncrease()}
        className={classNames(styles.arrowButton, nextDisabled && styles.disabled)}
      >
        <SlideIcon />
      </FocusableElement>
    </div>
  );
};
