import { takeEvery, call, put } from 'redux-saga/effects';
import { AppTracking } from 'global/appTracking';
import { downloadInvoice, DownloadInvoiceActions, downloadInvoiceFail, downloadInvoiceSuccess } from './actions';
import { downloadInvoiceAPI } from './api';

function* downloadInvoiceEffect({ payload: { invoiceNumber, invoiceType } }) {
  try {
    const invoicePdfResponse = yield call(downloadInvoiceAPI, invoiceNumber, invoiceType);

    const invoiceInBytes = new Uint8Array(invoicePdfResponse.data);
    const blob = new Blob([invoiceInBytes], { type: 'application/pdf' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'invoice.pdf';
    link.click();

    yield put(downloadInvoiceSuccess(invoiceNumber, invoiceType));

    AppTracking.downloadEvent({ action: 'Download Invoice' });
  } catch (e) {
    if (e.timeout) {
      yield put(downloadInvoice(invoiceNumber, invoiceType));
    } else {
      yield put(downloadInvoiceFail(e.message, invoiceNumber, invoiceType));
    }
  }
}

export const downloadInvoiceEffects = [
  takeEvery(DownloadInvoiceActions.DOWNLOAD_INVOICE, downloadInvoiceEffect),
];
