import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from 'components/dropdowns/Dropdown/components/Content/styles.module.scss';
import { DropdownContext } from 'components/dropdowns/Dropdown/index';

export const Content = ({ children, isCheckList }) => {
  const { isOpen } = useContext(DropdownContext);

  return (
    <div
      className={classNames(
        styles.contentContainer,
        isOpen && styles.open,
      )}
    >
      <div className={classNames(styles.content, isCheckList && styles.checkList)}>
        {children}
      </div>
    </div>
  );
};
