import React from 'react';
import { Text } from '../../../typography/Text';
import styles from './styles.module.scss';

export const Content = ({ label, value, intlLabelValue, intlValue }) => (
  <div className={styles.content}>
    <Text intl={label}>{intlLabelValue}</Text>

    {intlValue ? (
      <Text
        bold
        uppercase
        intl={intlValue}
      />
    ) : (
      <Text
        bold
        uppercase
      >
        {value}
      </Text>
    )}
  </div>
);
