import React from 'react';
import { useOrderModes } from 'services/order/order-mode/hook';
import { useSubmitBrikkOrder } from 'services/order/create-order/hook';
import { EmailsInput } from 'components/form-elements/inputs/EmailsInput';
import { Button } from 'components/form-elements/buttons/Button';
import { Text } from 'components/typography/Text';
import { Message } from 'semantic-ui-react';
import { isEmptyField, isEmptyArray } from 'services/order/order-mode/validate';
import { DragAndDrop } from 'components/drag-and-drop';
import { orderBy } from 'lodash';
import { ShowErrors } from './components/ShowErrors';
import styles from './styles.module.scss';

export const OrderList = () => {
  const {
    projectName,
    projectNameError,
    orderedBrikks,
    orderedBrikksError,
    emailRecipients,
    emailRecipientsError,
    setEmailRecipients,
    setProjectNameError,
    setEmailRecipientsError,
    setOrderedBrikksError,
    resetErrors,
    setOrderedBrikks,
  } = useOrderModes();

  const {
    loading,
    onSubmitCreateOrder,
    isSubmitted,
    error,
  } = useSubmitBrikkOrder();

  const errors = [projectNameError, orderedBrikksError, emailRecipientsError];

  const createOrder = () => {
    if (
      !isEmptyField(projectName)
      && !isEmptyArray(emailRecipients)
      && !isEmptyArray(orderedBrikks)
    ) {
      const order = orderedBrikks.map((brikk) => ({
        name: brikk.name,
        status: brikk.status,
        code: brikk.code,
      }));
      resetErrors();
      onSubmitCreateOrder(projectName, emailRecipients, order);
    } else {
      if (isEmptyField(projectName)) {
        setProjectNameError('projectNameError');
      } else {
        setProjectNameError('');
      }

      if (isEmptyArray(emailRecipients)) {
        setEmailRecipientsError('emailRecipientsError');
      } else {
        setEmailRecipientsError('');
      }

      if (isEmptyArray(orderedBrikks)) {
        setOrderedBrikksError('orderedBrikksError');
      } else {
        setOrderedBrikksError('');
      }
    }
  };

  const handleReorderBrikk = (reorder) => {
    const orderByPosition = orderBy(reorder, 'position').map((item) => item);
    setOrderedBrikks(orderByPosition);
  };

  return (
    <div className={styles.orderList}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.header}>
            <Text
              className={styles.columns}
              secondary
              intl="brikk"
              size={16}
            />
            <Text
              className={styles.columns}
              secondary
              intl="status"
              size={16}
            />
          </div>

          {orderedBrikks.length > 0 ? (
            <DragAndDrop
              isOrderList
              enabled
              order={orderedBrikks}
              draggables={orderedBrikks}
              onChange={(newBrikksOrder) => handleReorderBrikk(newBrikksOrder)}
            />
          ) : (
            <Text
              secondary
              intl="emptyList"
              size={16}
            />
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.rightContainer}>
            {isSubmitted && (
              <Message positive>
                <Message.Header>
                  <Text
                    className={styles.successText}
                    intl="successOrder"
                    size={16}
                  />
                </Message.Header>
                <Text
                  className={styles.successText}
                  intl="checkEmail"
                  size={16}
                />
              </Message>
            )}
            {error && (
              <Message negative>
                <Message.Header>
                  <Text
                    className={styles.errorText}
                    intl="failedOrder"
                    size={16}
                  />
                </Message.Header>
                <Text
                  className={styles.errorText}
                  intl="tryAgain"
                  size={16}
                />
              </Message>
            )}
            <Text
              className={styles.title}
              secondary
              intl="basketTitle"
              size={25}
            />
            <Text
              secondary
              intl="basketDescription"
              size={16}
            />
            <EmailsInput
              emailRecipients={emailRecipients}
              setEmailRecipients={setEmailRecipients}
            />
            <Button
              loading={loading}
              intl="orderButton"
              className={styles.greenBtn}
              onClick={createOrder}
            />

            {(projectNameError
              || emailRecipientsError
              || orderedBrikksError) && <ShowErrors errors={errors} />}
          </div>
        </div>
      </div>
    </div>
  );
};
