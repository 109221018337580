import React, { createContext, useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import { Text } from 'components/typography/Text';
import upgrades from 'assets/media/upgrades.png';
import { UpgradesList, CheckoutList } from 'components/brikks/home/Upgrades/components/UpgradesModal/components';

export const UpgradesModalContext = createContext();

export const UpgradesModal = () => {
  const [selectedUpgrades, setSelectedUpgrades] = useState([]);

  return (
    <UpgradesModalContext.Provider value={{ selectedUpgrades, setSelectedUpgrades }}>
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="upgrades" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="upgrades"
          >
            <ModalIntro>
              <ModalIntro.Image
                src={upgrades}
                alt="Upgrades"
              />

              <ModalIntro.Text intl="upgradesDescription" />
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="chooseUpgrade">
            <UpgradesList />
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="checkout">
            {
              selectedUpgrades.length
                ? <CheckoutList />
                : (
                  <Text
                    intl="noUpgradesAdded"
                    lightGray
                    size={16}
                  />
                )
            }
          </Brikk.Heading>
        </Grid.Column>

      </Grid>
    </UpgradesModalContext.Provider>
  );
};
