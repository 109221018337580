import React from 'react';
import classNames from 'classnames';
import bankIDLogoSrc from 'assets/media/bank-id.png';
import { Image } from 'components/media/Image';
import styles from './styles.module.scss';

export const BankIDLogo = ({ className, framed, centered }) => (
  <div className={classNames(framed && styles.framed, centered && styles.centered)}>
    <Image
      className={classNames(styles.bankIDLogo, className)}
      src={bankIDLogoSrc}
      alt="BankID Logotype"
    />
  </div>
);
