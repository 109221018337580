import React from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

export const PrimaryButton = ({ children, disabled, intl, name, onClick, loading }) => (
  <Button
    className={styles.button}
    name={name}
    loading={loading}
    disabled={disabled || loading}
    onClick={onClick}
  >
    {intl ? <FormattedMessage id={intl} /> : children}
  </Button>
);
