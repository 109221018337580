import React from 'react';
import { Text } from 'components/typography';
import { ShoppingBasket } from 'assets/icons/ShoppingBasket';
import { BaseButton } from 'components/form-elements/buttons/BaseButton';
import styles from './styles.module.scss';

export const CheckoutButton = ({ onClick }) => (
  <BaseButton
    className={styles.checkout}
    onClick={onClick}
  >
    <ShoppingBasket />

    <Text
      size={12}
      intl="addToCheckout"
      className={styles.title}
      bold
    />
  </BaseButton>
);
