import React from 'react';
import { Onboarding } from 'pages/onboarding/Onboarding';
import { Route } from 'react-router-dom';
import { ONBOARDING_WELCOME } from 'global/routes';
import { VXBOnboardingRoutes } from 'pages/onboarding/VXBOnboarding/routes';
import { Welcome } from './components/Welcome';
import { ContactDetails } from './components/ContactDetails';
import { ConfirmCompletion } from './components/ConfirmCompletion';

export const VXBOnboarding = () => (
  <Onboarding>
    <Route
      exact
      path={ONBOARDING_WELCOME}
      component={Welcome}
    />

    <Route
      exact
      path={VXBOnboardingRoutes.CONTACT_DETAILS}
      component={ContactDetails}
    />

    <Route
      exact
      path={VXBOnboardingRoutes.COMPLETED}
      component={ConfirmCompletion}
    />
  </Onboarding>
);
