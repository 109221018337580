import React from 'react';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { NavLink } from '../NavLink';

export const Navigator = ({ to, dark, onClick = () => {} }) => (
  <NavLink to={to}>
    <ProceedButton onClick={onClick}>
      <ProceedButton.Button intl="moveOn" />
      <ProceedButton.CircledArrow dark={dark} />
    </ProceedButton>
  </NavLink>
);
