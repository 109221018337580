import React, { useContext } from 'react';
import { BrikkContext } from 'components/Brikk/index';
import { Card } from 'semantic-ui-react';
import styles from './styles.module.scss';

export const Button = ({ children }) => {
  const { isLoading, error } = useContext(BrikkContext);

  /* eslint-disable max-len */
  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child, { loading: isLoading, error }));

  return (
    <Card.Content
      extra
      className={styles.brikkFooter}
    >
      {childrenWithProps}
    </Card.Content>
  );
};
