import React from 'react';
import { ProceedButton } from '../ProceedButton';
import styles from './styles.module.scss';

export const NextStep = ({ onClick, disabled }) => (
  <div className={styles.nextStep}>
    <ProceedButton
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <ProceedButton.Button intl="nextStep" />
      <ProceedButton.CircledArrow dark />
    </ProceedButton>
  </div>
);
