import React from 'react';
import classNames from 'classnames';
import { Card } from 'semantic-ui-react';
import { useTheme } from 'global/themes';
import { FormattedMessage, useIntl } from 'react-intl';

const BrikkCalendar = (props) => {
  const { month, date, text1, text2, text3 } = props;

  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

  const { theme } = useTheme();

  const intl = useIntl();

  return (
    <div className="brikk-calendar">
      <Card className={theme.boxShadow}>
        <Card.Content className="header">
          <div>
            <FormattedMessage
              id={intl.formatMessage({ id: months[parseInt(month, 10) - 1] }).substr(0, 3)}
            />
          </div>
        </Card.Content>

        <Card.Content className={classNames('body', theme.iconBackgroundSecondaryColor)}>
          <div className={classNames('calendar-date', theme.textColor)}>
            {date}
          </div>

          <div className={classNames('calendar-text text-1')}>
            {text1}
          </div>

          <div className={classNames('calendar-text text-2')}>
            {text2}
          </div>

          <div className={classNames('calendar-text text-3')}>
            {text3}
          </div>
        </Card.Content>
      </Card>
    </div>
  );
};

export default BrikkCalendar;
