import React from 'react';
import styles from 'components/brikks/home/Invoices/components/InvoicesPreview/styles.module.scss';
import { CircledCheckmarkIcon } from 'assets/icons/CircledCheckmarkIcon';
import { CircledWarningIcon } from 'assets/icons/CircledWarningIcon';
import { ContentField } from 'components/ContentField';
import { Text } from 'components/typography/Text';
import { FormattedMessage } from 'react-intl';
import { CircledCloseIcon } from 'assets/icons/CircledCloseIcon';

export const InvoicesPreview = ({ invoices }) => {

  const invoiceStatusIcon = (status) => {
    switch (status) {
      case 'Paid':
        return <CircledCheckmarkIcon />;
      case 'Unpaid':
        return <CircledCloseIcon />;
      case 'Pending':
        return <CircledWarningIcon />;
      default:
        return <CircledWarningIcon />;
    }
  };

  return (
    <div>
      {invoices.length ? (
        <div className={styles.invoices}>
          {invoices.map(invoice => (
            <ContentField key={invoice.invoiceNumber}>
              { invoiceStatusIcon(invoice.status) }

              <ContentField.Content
                intlLabelValue={invoice.period.toLowerCase()}
                intlValue={invoice.status.toLowerCase()}
              />
            </ContentField>
          ))}
        </div>
      ) : (
        <div className={styles.noInvoices}>
          <Text
            center
            className={styles.noData}
          >
            <FormattedMessage
              id="invoicesEmpty"
              values={{
                bold: (...chunks) => (
                  <Text
                    className={styles.noData}
                    size={14}
                    element="span"
                    bold
                    uppercase
                  >
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Text>

          <div className={styles.rentPreview}>
            <ContentField>
              <CircledWarningIcon disabled />

              <ContentField.Content
                label="rentUpcoming"
                value=""
              />
            </ContentField>

            <ContentField>
              <CircledCheckmarkIcon disabled />

              <ContentField.Content
                label="rentPaid"
                value=""
              />
            </ContentField>

            <ContentField>
              <CircledCloseIcon disabled />

              <ContentField.Content
                label="rentUnpaid"
                value=""
              />
            </ContentField>
          </div>
        </div>
      )}
    </div>
  ); 
};
