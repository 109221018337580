import React, { useContext } from 'react';
import { HOME_PAGE } from 'global/routes';
import onboardingCompletedSrc from 'assets/media/onboarding-completed.png';
import { Text } from 'components/typography';
import { ModalCard } from 'components/cards/ModalCard';
import { FormattedMessage } from 'react-intl';
import { AppContext } from 'AppContext';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { Grid } from 'components/layout/Grid';
import { OnboardingImage } from 'pages/onboarding/OnboardingImage';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';
import styles from './styles.module.scss';

export const ConfirmCompletion = () => {
  const { userSettings: { updateUserSettings } } = useContext(AppContext);

  const { onRedirect } = useRedirect(HOME_PAGE);

  const onCompleteOnboarding = () => {
    updateUserSettings({ onboardingPassed: true });
    onRedirect();
  };
  
  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <OnboardingImage imageSrc={onboardingCompletedSrc} />
        </Grid.Column>

        <Grid.Column>
          <Text
            element="h1"
            size={42}
            thin
          >
            <FormattedMessage
              id="onboardingCompleted"
              values={{
                span: (...chunks) => (
                  <span className={styles.strong}>
                    {chunks}
                  </span>
                ),
              }}
            />
          </Text>
        </Grid.Column>

        <NextStep onClick={onCompleteOnboarding} />
      </OnboardingGrid>
    </ModalCard>
  );
};
