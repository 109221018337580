import { AWS_API_URL } from 'global/environment';
import { authorize, postRequest } from 'utils/http';
import { mapDataForBackend } from './mapData';

export async function terminateAgreement(agreementData) {
  return postRequest(
    `${AWS_API_URL}/agreements/${agreementData.agreementNumber}/terminate`,
    mapDataForBackend(agreementData),
    await authorize(),
  );
}
