import React, { useMemo, useState } from 'react';
import { Text } from 'components/typography/Text';
import { FormattedMessage } from 'react-intl';
import { AddFeed } from 'components/brikks/society/RSSFeed/components/AddFeed';
import { InnerModal } from 'components/modals/InnerModal';
import { useRSSFeed } from 'services/rss-feed/rss-feed';
import { FocusableElement } from 'components/FocusableElement';
import styles from './styles.module.scss';

export const AddFeedButton = () => {
  const { rssFeed } = useRSSFeed();
  const [isAddFeedModalOpened, setIsAddFeedModalOpened] = useState(false);

  const customFeedsCount = useMemo(() => rssFeed.filter(feed => !feed.default).length, [rssFeed]);

  const maxCustomFeeds = 5;

  const openAddModal = () => {
    if (customFeedsCount < maxCustomFeeds) {
      setIsAddFeedModalOpened(true);
    }
  };

  return (
    <>
      <FocusableElement onClick={openAddModal}>
        <Text
          dark
          lightGray={customFeedsCount === maxCustomFeeds}
          clickable={customFeedsCount < maxCustomFeeds}
          small
        >
          <FormattedMessage id="addFeed" />
        </Text>
      </FocusableElement>

      <Text
        className={styles.grayText}
        small
      >
        {`${customFeedsCount}/${maxCustomFeeds} `}
        <FormattedMessage id="customFeeds" />
      </Text>

      <InnerModal
        isOpen={isAddFeedModalOpened}
        onClose={() => setIsAddFeedModalOpened(false)}
      >
        <AddFeed onAdd={() => setIsAddFeedModalOpened(false)} />
      </InnerModal>
    </>
  );
};
