import React, { useState } from 'react';
import { Text } from 'components/typography/Text';
import { BaseModal } from 'components/modals/BaseModal';
import { BankIdSecondSignature } from 'components/bank-id/BankIdSecondSignature';
import classNames from 'classnames';
import { ShouldRender } from 'components/ShouldRender';
import { TerminateDetails } from 'components/brikks/home/TerminateAgreement/components/TerminateDetails';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { BRE } from 'global/tenant-config/tenants';
import { FrejaeIDLogo } from '../FrejaeIDLogo';
import { BankIDSigningPopup } from '../BankIDSigning/components/BankIDSigningPopup';
import { BankIDLogo } from '../BankIDLogo';
import styles from './styles.module.scss';

export const BankIDMultipleSigning = ({
  secondPersonData,
  secondPersonSignedTermination,
  children,
  userVisibleData,
  showTerminationDetails = false,
  setTerminationDetails,
  finalTerminationDate,
  disabled = false,
  showConfiramtionMessage = false,
}) => {
  const [isSigningPopupOpen, setIsSigningPopupOpen] = useState(false);
  const [isTerminationDetailsPopupOpen, setIsTerminationDetailsPopupOpen] = useState(false);
  const [email, setEmail] = useState(secondPersonData?.email);
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const isEmail = (email) => /[\w\d-]+@[\w\d-]+\.[\w\d-]+/.test(email);

  const isDisabledBankId = secondPersonData ? !isEmail(email) : disabled;

  return (
    <div className={styles.bankIDSigning}>
      <ShouldRender condition={tenantId !== BRE}>
        <div className={styles.sign}>
          <div className={styles.bankIDLogo}>
            <BankIDLogo framed />
          </div>
          <div className={styles.bankIDTextContainer}>
            <Text
              intl="bankIDSig"
              className={classNames(styles.bankIDText, isDisabledBankId && styles.disabled)}
              onClick={() => (
                !showTerminationDetails ? setIsSigningPopupOpen(!isDisabledBankId) : setIsTerminationDetailsPopupOpen(!isDisabledBankId)
              )}
              intlValues={{
                bold: (...chunks) => (
                  <Text
                    size={14}
                    element="span"
                    bold
                  >
                    {chunks}
                  </Text>
                ),
              }}
              size={14}
            />
          </div>
        </div>
      </ShouldRender>

      <ShouldRender condition={tenantId === BRE}>
        <div className={styles.sign}>
          <div className={styles.bankIDLogo}>
            <FrejaeIDLogo framed />
          </div>
          <div className={styles.bankIDTextContainer}>
            <Text
              intl="frejaeIDThisDevice"
              className={classNames(styles.bankIDText, isDisabledBankId && styles.disabled)}
              intlValues={{
                bold: (...chunks) => (
                  <Text
                    size={14}
                    element="span"
                    bold
                  >
                    {chunks}
                  </Text>
                ),
              }}
              size={14}
            />
          </div>
        </div>

        <div className={styles.sign}>
          <div className={styles.bankIDLogo}>
            <FrejaeIDLogo framed />
          </div>
          <div className={styles.bankIDTextContainer}>
            <Text
              intl="frejaeIDAnotherDevice"
              className={classNames(styles.bankIDText, isDisabledBankId && styles.disabled)}
              intlValues={{
                bold: (...chunks) => (
                  <Text
                    size={14}
                    element="span"
                    bold
                  >
                    {chunks}
                  </Text>
                ),
              }}
              size={14}
            />
          </div>
        </div>

        <div className={styles.sign}>
          <div className={styles.bankIDLogo}>
            <BankIDLogo framed />
          </div>
          <div className={styles.bankIDTextContainer}>
            <Text
              intl="bankIDThisDevice"
              className={classNames(styles.bankIDText, isDisabledBankId && styles.disabled)}
              onClick={() => (
                !showTerminationDetails
                  ? setIsSigningPopupOpen(!isDisabledBankId)
                  : setIsTerminationDetailsPopupOpen(!isDisabledBankId)
              )}
              intlValues={{
                bold: (...chunks) => (
                  <Text
                    size={14}
                    element="span"
                    bold
                  >
                    {chunks}
                  </Text>
                ),
              }}
              size={14}
            />
          </div>
        </div>

        <div className={styles.sign}>
          <div className={styles.bankIDLogo}>
            <BankIDLogo framed />
          </div>
          <div className={styles.bankIDTextContainer}>
            <Text
              intl="bankIDAnotherDevice"
              className={classNames(styles.bankIDText, isDisabledBankId && styles.disabled)}
              onClick={() => (
                !showTerminationDetails
                  ? setIsSigningPopupOpen(!isDisabledBankId)
                  : setIsTerminationDetailsPopupOpen(!isDisabledBankId)
              )}
              intlValues={{
                bold: (...chunks) => (
                  <Text
                    size={14}
                    element="span"
                    bold
                  >
                    {chunks}
                  </Text>
                ),
              }}
              size={14}
            />
          </div>
        </div>
      </ShouldRender>

      <ShouldRender condition={!secondPersonSignedTermination && !!secondPersonData}>
        <BankIdSecondSignature
          value={email}
          onChange={setEmail}
          isError={!isEmail(email)}
        />
      </ShouldRender>

      <ShouldRender condition={!!isTerminationDetailsPopupOpen}>
        <TerminateDetails
          isOpen={!!isTerminationDetailsPopupOpen}
          setIsOpen={setIsTerminationDetailsPopupOpen}
          setTerminationDetails={setTerminationDetails}
          setIsSigningPopupOpen={setIsSigningPopupOpen}
          finalTerminationDate={finalTerminationDate}
        />
      </ShouldRender>

      <BaseModal
        nested
        closeOnOutsideClick
        onClose={() => setIsSigningPopupOpen(false)}
        isOpen={isSigningPopupOpen}
      >
        <BankIDSigningPopup
          showConfiramtionMessage={showConfiramtionMessage}
          onClose={() => setIsSigningPopupOpen(false)}
          userVisibleData={userVisibleData}
        >
          {children}
        </BankIDSigningPopup>
      </BaseModal>
    </div>
  );
};
