import { useDispatch, useSelector } from 'react-redux';
import { downloadInvoice } from './actions';

export function useDownloadInvoice(invoiceNumber, invoiceType) {
  const { loading, error } = useSelector(state => state.downloadInvoice);
  const dispatch = useDispatch();

  return {
    onDownloadInvoice: () => dispatch(downloadInvoice(invoiceNumber, invoiceType)),
    loading: loading[invoiceNumber],
    error: error[invoiceNumber],
  };
}
