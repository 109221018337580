import React, { useContext } from 'react';
import { useMaintenanceProducts } from 'services/maintenance/maintenance-products/hook';
import { Image } from 'components/media/Image';
import { Text } from 'components/typography/Text';
import classNames from 'classnames';
import { Brikk } from 'components/Brikk';
import { Placeholder } from 'semantic-ui-react';
import { useTheme } from 'global/themes';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { FocusableElement } from 'components/FocusableElement';
import styles from './styles.module.scss';

export const UpgradeProducts = () => {
  const { selectedSurface, setSelectedProduct } = useContext(UpgradeContext);
  const { products, loading, error } = useMaintenanceProducts(selectedSurface?.type, selectedSurface?.unit, selectedSurface?.measure);
  const { theme } = useTheme();

  return (
    <Brikk.Heading
      id="maintenanceProducts"
      heading="options"
    >
      {
        loading && (
          <Placeholder>
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
          </Placeholder>
        )
      }
      {!loading && (products.length ? (
        <div className={styles.productsDetails}>
          {products.map(product => (
            <FocusableElement
              key={product.code}
              className={styles.product}
              onClick={() => setSelectedProduct(product)}
            >
              <Image
                className={styles.image}
                src={product.scaledImageUrl}
                alt={product.name}
              />

              <Text size={10}>
                {product.name}
              </Text>
            </FocusableElement>
          ))}
        </div>
      ) : (
        <Text
          intl={error || 'maintenanceNoSurface'}
          size={18}
        />
      ))}
    </Brikk.Heading>
  );
};
