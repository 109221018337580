import React from 'react';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { Amplify } from 'aws-amplify';
import { GA_TRACKING } from 'global/environment';
import { AppTracking } from 'global/appTracking';
import * as serviceWorker from './serviceWorker';
import App from './App';

const appConfig = process.env;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: appConfig.REACT_APP_region,
    userPoolId: appConfig.REACT_APP_userPool,
    userPoolWebClientId: appConfig.REACT_APP_clientId,
    tokenScopesArray: appConfig.REACT_APP_tokenScopes,
  },
});

AppTracking.initialize(GA_TRACKING);
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App />);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
