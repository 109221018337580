import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';

const url = `${AWS_API_URL}/renovation/get-materials`;

export async function getMaintenanceProductsAPI(type, unit, measure, locale) {
  return getRequest(url, await authorize({
    params: {
      typeName: type,
      unitName: unit,
      measure,
      language: locale,
    },
  }));
}
