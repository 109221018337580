import React from 'react';
import { ProceedButton } from '../ProceedButton';
import styles from './styles.module.scss';

export const PrevStep = ({ onClick }) => (
  <div className={styles.prevStep}>
    <ProceedButton onClick={onClick}>
      <ProceedButton.CircledArrow
        dark
        left
      />
      <ProceedButton.Button intl="goBack" />
    </ProceedButton>
  </div>
);
