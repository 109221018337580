import React, { useContext } from 'react';
import { Dropdown } from 'components/dropdowns/Dropdown';
import { Text } from 'components/typography/Text';
import { useAgreements } from 'services/agreements/agreements';
import { CheckMarkIcon } from 'assets/icons/CheckMarkIcon';
import { MovingOutModalContext } from 'components/brikks/home/MovingOut/components/MovingOutModal/index';
import styles from './styles.module.scss';

export const TabMenu = ({ menuName, subMenus, isCheckList }) => {
  const { agreements } = useAgreements();
  const { activeMenu, setActiveMenu } = useContext(MovingOutModalContext);

  const getSubMenus = () => {
    switch (subMenus) {
      case 'agreements':
        return agreements;
      default:
        return null;
    }
  };

  return (
    <Dropdown
      key={menuName}
      open={activeMenu === menuName}
    >
      <Dropdown.Item
        intl={menuName}
        isCheckList={isCheckList}
        onClick={() => setActiveMenu(menuName)}
      />
      <Dropdown.Content
        isCheckList={isCheckList}
      >
        {getSubMenus()?.map(item => (
          <Dropdown.SubItem
            key={item.agreementNumber}
          >
            <div className={styles.subItem}>
              <CheckMarkIcon
                activeGrey={item.status !== 'completed'}
                activeGreen={item.status === 'completed'}
              />

              <Text size={16}>{item.productType}</Text>
            </div>
          </Dropdown.SubItem>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
};
