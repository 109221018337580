import React from 'react';
import classNames from 'classnames';
import { useTheme } from '../../global/themes';
import styles from './styles.module.scss';

export const Tooltip = ({ element, children }) => {
  const { theme } = useTheme();

  return (
    <div className={styles.tooltip}>
      <div className={styles.element}>
        {element}
      </div>

      <div className={classNames(styles.content, theme.cardBackgroundColor)}>
        {children}
      </div>
    </div>
  );
};
