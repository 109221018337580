import React from 'react';
import { useWeather } from 'services/weather/hook';
import { getIconForWeather } from 'components/brikks/neighbourhood/Weather/components/WeatherSlide';
import { ForecastItem } from 'components/brikks/neighbourhood/Weather/components/WeatherModal/components/ForecastItem';
import styles from 'components/brikks/neighbourhood/Weather/components/WeatherModal/components/WeekForecast/styles.module.scss';
import { Text } from 'components/typography/Text';

export const WeekForecast = () => {

  const { weather: { daily } } = useWeather();

  return (
    <div className={styles.WeekForecast}>

      <Text
        className={styles.heading}
        intl="theWeek"
        center
        medium
        uppercase
      />

      <div className={styles.itemsWrapper}>
        { daily.map((item, index) => {
          if (index < 7) {
            return (
              <ForecastItem
                labelIntl={item.day}
                icon={getIconForWeather(item.weatherCondition)}
                temp={item.tempMax}
                tempMin={item.tempMin}
                key={index}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
