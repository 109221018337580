import React from 'react';
import { Text } from 'components/typography/Text';
import { ProductInfo } from
  'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/ProductDetails/components/ProductInfo';
import styles from './styles.module.scss';

export const ProductDescription = ({ selectedProduct }) => (
  <div className={styles.details}>
    <Text
      size={18}
      intl="facts"
    />
    <ProductInfo
      label="productName"
      value={selectedProduct?.name}
    />
    <ProductInfo
      label="productNumber"
      value={selectedProduct?.code}
    />
    <Text>
      {selectedProduct?.description}
    </Text>

    <div className={styles.specificationContent}>
      <Text
        size={18}
        intl="specifications"
      />
      <ProductInfo
        bold
        label="trademark"
        value={selectedProduct?.brand}
      />
    </div>
  </div>
);

