import React from 'react';
import { Card } from 'components/cards/Card';
import styles from './styles.module.scss';

export const MediumCard = ({ children }) => (
  <Card
    radius="lg"
    className={styles.mediumCard}
  >
    {children}
  </Card>
);
