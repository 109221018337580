import React from 'react';
import { Text } from 'components/typography/Text';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const IconNumText = ({ number, text, icon, uppercase = true }) => (
  <div className={styles.numText}>
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        {icon}
      </div>

      <ShouldRender condition={number}>
        <Text
          primary
          xxl4
        >
          {number}
        </Text>
      </ShouldRender>
    </div>

    <Text
      center
      size={14}
      uppercase={uppercase}
    >
      {text}
    </Text>
  </div>
);
