import React from 'react';
import classNames from 'classnames';
import { ImageWithOverlay } from 'components/media/ImageWithOverlay';
import { useRouteMatch } from 'react-router-dom';
import { NavLink } from 'components/links/NavLink';
import styles from './styles.module.scss';

export const GalleryImage = ({ imageSrc, overlayIntl, activeRoute }) => {
  const match = useRouteMatch({ path: activeRoute });

  return (
    <NavLink to={activeRoute}>
      <ImageWithOverlay
        className={classNames(
          styles.smallImage,
          {
            [styles.active]: match?.isExact,
          },
        )}
        imageSrc={imageSrc}
      >
        <ImageWithOverlay.SmallOverlay intl={overlayIntl} />
      </ImageWithOverlay>
    </NavLink>
  );
};
