import { takeEvery, call, put } from 'redux-saga/effects';
import { AppTracking } from '../../../global/appTracking';
import {
  downloadAgreement,
  DownloadAgreementActions,
  downloadAgreementFail,
  downloadAgreementSuccess,
} from './actions';
import { downloadAgreementAPI } from './api';

function* downloadAgreementEffect({ payload }) {
  try {
    const agreementInBase64 = yield call(downloadAgreementAPI, payload);

    const linkSource = `data:application/pdf;base64,${agreementInBase64.content}`;
    const downloadLink = document.createElement('a');
    const fileName = 'agreement.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    yield put(downloadAgreementSuccess(payload));

    AppTracking.downloadEvent({ action: 'Download Agreement' });
  } catch (e) {
    if (e.timeout) {
      yield put(downloadAgreement(payload));
    } else {
      yield put(downloadAgreementFail(e.message, payload));
    }
  }
}

export const downloadAgreementEffects = [
  takeEvery(DownloadAgreementActions.DOWNLOAD_AGREEMENT, downloadAgreementEffect),
];
