import React from 'react';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';

export const ProductInfo = ({ label, value, bold }) => (
  <div className={styles.info}>
    <Text
      bold={bold}
      intl={label}
      className={styles.label}
    />

    <Text>
      {value}
    </Text>
  </div>
);
