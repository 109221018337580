import { useMutation, useQueryClient } from 'react-query';
import { terminateAgreement } from './api';

export function useTerminateAgreement() {
  const queryClient = useQueryClient();
  const { isLoading, error, mutate, isSuccess } = useMutation(
    (agreementData) => terminateAgreement(agreementData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('agreements');
      },
    },
  );

  return {
    terminateAgreements: mutate,
    loading: isLoading,
    error: error?.message,
    isSuccess,
  };
}
