import React from 'react';
import classNames from 'classnames';
import { Form } from 'semantic-ui-react';
import { Text } from '../../../typography/Text';
import { useTheme } from '../../../../global/themes';
import styles from './styles.module.scss';

export const Input = ({ label, name, disabled, defaultValue, onChange }) => {
  const { theme } = useTheme();

  return (
    <div className={styles.content}>
      <Text intl={label} />

      <Form.Input
        className={classNames(
          theme.textColor,
          styles.input,
          disabled && styles.disabled,
        )}
        disabled={disabled}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  );
};
